<template>
  <div class="ContainerFooter" :class="getRightNavigation">
    <div class="coupon">
      <div class="remove" @click="setCart([])"></div>
      <div class="add-coupon" @click="setRightNavigation($options.NEW_COUPON)">+ Coupon</div>
    </div>
    <div class="coupon-list" v-if="getCartCoupon.active">
      <div class="active-coupon">
        <div class="remove" @click="setCartCoupon($options.EMPTY_COUPON)"></div>
        <div class="name">{{getCartCoupon.name}}</div>
        <div class="value"> {{ "-" + getCartCoupon.import + (getCartCoupon.import && getCartCoupon.import.includes('%')?'':'€') }} </div>
      </div>
    </div>
    <div class="total" >
      <div class="title"> Totale </div>
      <div class="value"> {{'€ '+ getTotal }} </div>
    </div>
    <div class="cta" v-if="getRightNavigation==$options.CUSTOMERS_LIST">
      <div class="new"  @click="newCustomer"> Nuovo Cliente </div>
      <div class="select" :class="{inactive:getSelectedCustomer==null || getIsLoadingCustomers}" @click="selectCustomer"> 
        <span v-if="getIsLoadingCustomers" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Seleziona </span>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.CART">
      <div class="hold" @click="holdClick"> Hold </div>
      <div class="checkout" :class="{inactive:getSelectedCustomer==null}" @click="toPaymentMethod()"> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Checkout </span>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.CALENDAR">
      <div class="customer-continue" @click="setTimeRange"> Conferma </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.CUSTOMER_CHOICE">
      <div class="customer-continue" :class="{inactive:getIsLoadingCustomer || !isNotMissingInfo}" @click="customerContinue"> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span>
        <span v-else> Continua con questo Cliente  </span>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.NOTE">
      <div class="customer-continue" @click="setOrderNote(getOrderNoteToAdd);setRightNavigation($options.CART)"> Conferma </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.SHIPPING_PLACE">
      <div class="shipping-continue" @click="shippingContinue" :class="{inactve:getSelectedShipping==''}"> Continua </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.ONLY_TELEPHONE">
      <div class="shipping-continue" :class="{inactive:getTelephone==''}" @click="setRightNavigation($options.CART)"> Continua </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.SHIPPING_CHOICE">
      <div class="shipping-continue" @click="setRightNavigation($options.CART)" :class="{inactive:Object.keys(getSelectedAddress).length === 0}"> Continua con questo Indirizzo </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.NEW_SHIPPING">
      <div class="shipping-continue" @click="addNewShipping" :class="{inactive:!getValidAddress || getIsLoadingCustomer}"> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Aggiungi Indirizzo </span>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.SAVE_NEW_CUSTOMER">
      <div class="shipping-continue" :class="{inactive:!isActiveButton || getIsLoadingCustomer}" @click="addNewCustomer" v-if="getRegistrationType==$options.REGISTRATION_TYPE_USER" > 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Conferma Registrazione </span>
      </div>
      <div class="shipping-continue" :class="{inactive:!isActiveButton ||getIsLoadingCustomer}" @click="addNewCustomerGuest" v-else> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Conferma Cliente Ospite </span>
      </div>
      <div class="registration-label" v-if="getRegistrationType==$options.REGISTRATION_TYPE_USER"> Il cliente riceverà una mail di conferma. </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.NEW_CUSTOMER">
      <div class="customer-continue" :class="{inactive:getIsLoadingCustomer}" @click="setUserSearchWord('');setRightNavigation($options.SAVE_NEW_CUSTOMER)" v-if="!getSelectedCustomer"> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Registra nuovo Cliente </span>
      </div>
      <div class="customer-continue" :class="{inactive:getIsLoadingCustomer || !isNotMissingInfo}" @click="customerContinue" v-else> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span>
        <span v-else> Continua con questo Cliente  </span>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.NEW_COUPON">
      <div class="apply-coupon" :class="{inactive:getIsLoadingCoupon || !getCouponToAdd.active}" @click="setCartCoupon({...getCouponToAdd});setRightNavigation($options.CART);"> 
        <span v-if="getIsLoadingCoupon" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Applica </span>
      </div>
      <div class="custom-coupon" @click="setCouponToAdd({type:'name',value:''});setCouponToAdd({type:'import',value:0});setRightNavigation($options.CUSTOM_COUPON);"> 
        <span> Inserisci Sconto Manuale  </span>
      </div>
      <div class="total-coupon"> 
        <div class="label"> Totale da pagare</div>
        <div class="value"> {{'€ '+getTotal}}</div>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.CUSTOM_COUPON">
      <div class="apply-coupon" :class="{inactive:getCouponToAdd.name=='' || parseFloat(getCouponToAdd.import)==0}" @click="setRightNavigation($options.CART);setCouponToAdd({type:'active',value:true});setCartCoupon({...getCouponToAdd});"> 
        <span> Applica </span>
      </div>
      <div class="total-coupon"> 
        <div class="label"> Totale da pagare</div>
        <div class="value"> {{'€ '+getTotal}}</div>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.PAYMENT_METHOD">
      <div class="cart-discount" v-if="getCartDiscount>0"> Questo carrello ha uno sconto automatico di <span>{{getCartDiscount+ '€'}} </span> </div>
      <div class="confirm-order" @click="confirmOrder();" :class="{inactive:getPaymentType=='' || getIsLoadingCustomer}"> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Conferma Ordine </span>
      </div>
      <div class="total-to-pay">
        <div class="label"> Totale da pagare</div>
        <div class="value"> {{'€ '+getCartTotal}}</div>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.ACCONTO">
      <div class="cart-discount" v-if="getCartDiscount>0"> Questo carrello ha uno sconto automatico di <span>{{getCartDiscount+ ' €'}}</span> </div>
      <div class="confirm-order" @click="confirmOrder();" :class="{inactive:getAcconto<=0 || getTotal<0 || getIsLoadingCustomer}"> 
        <span v-if="getIsLoadingCustomer" class="loader"> <b-spinner type="grow" label="Spinning"></b-spinner> </span> 
        <span v-else> Conferma Ordine </span>
      </div>
      <div class="total-to-pay"> 
        <div class="label"> Totale da pagare</div>
        <div class="value"> {{'€ '+getCartTotal}}</div>
      </div>
    </div>
    <div class="cta" v-else-if="getRightNavigation==$options.ORDER_CONFIRMED">
      <div class="print-receipt" @click="printReceipt()"> 
        <span> Stampa </span>
      </div>
      <div class="order-detail" @click="toOrderDetail()"> 
        <span> Dettagli Ordine </span>
      </div>
      <div class="new-order" @click="fromOrderConfirmedToCart()"> 
        <span> Nuovo Ordine </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Static from '@/utils/Static';
import axios from 'axios';
import lodash from 'lodash';

export default {
  name: 'ContainerFooter',
  CUSTOMERS_LIST:Static.CUSTOMERS_LIST,
  CART:Static.CART,
  CUSTOMER_CHOICE:Static.CUSTOMER_CHOICE,
  SHIPPING_PLACE:Static.SHIPPING_PLACE,
  SHIPPING_CHOICE:Static.SHIPPING_CHOICE,
  NEW_CUSTOMER:Static.NEW_CUSTOMER,
  SAVE_NEW_CUSTOMER:Static.SAVE_NEW_CUSTOMER,
  REGISTRATION_TYPE_USER:Static.REGISTRATION_TYPE_USER,
  NEW_SHIPPING:Static.NEW_SHIPPING,
  NEW_COUPON:Static.NEW_COUPON,
  EMPTY_COUPON:Static.EMPTY_COUPON,
  EMPTY_CUSTOMER:Static.EMPTY_CUSTOMER,
  CUSTOM_COUPON:Static.CUSTOM_COUPON,
  PAYMENT_METHOD:Static.PAYMENT_METHOD,
  ORDER_CONFIRMED:Static.ORDER_CONFIRMED,
  ONLY_TELEPHONE:Static.ONLY_TELEPHONE,
  ACCONTO:Static.ACCONTO,
  NOTE:Static.NOTE,
  CALENDAR:Static.CALENDAR,
  computed:{
    ...mapGetters(['getRightNavigation','getTotal','getSelectedCustomer','getCart','getUrlCustomer','getUser','getSelectedShipping'
    ,'getSelectedAddress','getValidAddress','getIsLoadingCustomer','getUrlSaveAddress','getAddressToAdd','getRegistrationType','getCustomerToAdd'
    ,'getTotalToPay','getUrlSaveOrder','getRegistrationType','getOrderNoteToAdd','getUrlGetOrders','getCancelTokenSearchOrder','getTimeRange'
    ,'getMissingInfo','getDefaultBilling','getUrlSaveBilling'
    ,'getCompletedOrder','getAcceptedGDPR','getUrlOrderPrice','getCartTotal','getCartDiscount','getIsValidMail','getTelephone','getUrlSendEmail'
    ,'getPaymentType','getAcconto','getUrlSaveCustomer','getIsLoadingCustomers','getIsLoadingCoupon','getCouponToAdd','getCartCoupon','getOrderNoteToAdd']),
    isActiveButton(){
      return this.getCustomerToAdd.firstname!=''  && this.getCustomerToAdd.lastname!='' && this.getCustomerToAdd.addresses[0].telephone!=''
        && (this.getCustomerToAdd.email!='' && Static.EMAIL_REGEX.test(this.getCustomerToAdd.email))
        && this.getAcceptedGDPR 
        && (
          (this.getCustomerToAdd.password.length>6 && this.getCustomerToAdd.addresses[0].country!='' && this.getCustomerToAdd.addresses[0].firstname!='' && this.getCustomerToAdd.addresses[0].lastname!='' && this.getCustomerToAdd.addresses[0].postcode!='' && this.getCustomerToAdd.addresses[0].city!='' && this.getCustomerToAdd.addresses[0].region!='' && this.getCustomerToAdd.addresses[0].streetname!='' && this.getIsValidMail) 
          || this.getRegistrationType==Static.REGISTRATION_TYPE_GUEST
        )
    },
    isNotMissingInfo(){
      return this.getMissingInfo.firstname && this.getMissingInfo.firstname!='' && this.getMissingInfo.lastname && this.getMissingInfo.lastname!='' &&
        this.getMissingInfo.city && this.getMissingInfo.city!='' && this.getMissingInfo.country && this.getMissingInfo.country!='' &&
        this.getMissingInfo.postcode && this.getMissingInfo.postcode!='' && this.getMissingInfo.telephone && this.getMissingInfo.telephone!='' &&
        this.getMissingInfo.streetname && this.getMissingInfo.streetname!='' && this.getMissingInfo.region && this.getMissingInfo.region!='' 
    }
  },methods:{
    ...mapActions(['setCart','addHoldCarts','setRightNavigation','setSelectedCustomer','setEmptyAddressToAdd','setIsLoadingCustomers','setCouponToAdd'
    ,'setCompletedOrder','setShowReceipt','setPaymentType','setAcconto','setSelectedAddress','setOrderNote','setOrderList','setCancelTokenSearchOrder'
    ,'setOrderNoteToAdd','setOrderSearchWord','setNavigation','deleteUser','setUserSearchWord','setCartTotal','setCartDiscount','setTelephone'
    ,'setMissingInfo'
    ,'addAddressToSelectedCustomer','setEmptyCustomerToAdd','setRegistrationType','setIsLoadingCustomer','setCartCoupon','setCouponToAdd','setDataToPrint']),
    holdClick(){
      this.addHoldCarts(this.getCart);
      this.setCart([]);
    },
    missingFieldCheck(type){
      return !this.getDefaultBilling || !this.getDefaultBilling.address || !this.getDefaultBilling.address[type] || this.getDefaultBilling.address[type]==''
    },
    newCustomer(){
      this.setSelectedCustomer(null);
      this.setRightNavigation(Static.NEW_CUSTOMER);
    },
    selectCustomer(){
        var urlCustomerWithParameters=this.getUrlCustomer.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
        urlCustomerWithParameters=urlCustomerWithParameters
        .replace('/phone/CUSTOMER-PHONE/info/CUSTOMER-FIRSTNAME-LASTNAME','')
        .replace(Static.CUSTOMER_EMAIL,encodeURIComponent(this.getSelectedCustomer.email));
        this.setIsLoadingCustomers(true);
        axios.get(urlCustomerWithParameters)
        .then(res=>{
          this.setSelectedCustomer(res.data[0]);
          var missingInfo=res.data[0].addresses.filter(item=>item.defaultbilling).length>0?lodash.cloneDeep(res.data[0].addresses.filter(item=>item.defaultbilling)[0]).address:{...Static.EMPTY_MISSING_INFO}
          this.setMissingInfo(missingInfo);
          this.setRightNavigation(Static.CUSTOMER_CHOICE);
        })
        .catch(err=>{if(err.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        }})
        .finally(()=>this.setIsLoadingCustomers(false))
    },
    shippingContinue(){
      if(this.getSelectedShipping==Static.SHOP && this.getRegistrationType==Static.REGISTRATION_TYPE_USER) {
        this.setRightNavigation(Static.ONLY_TELEPHONE);
        this.setTelephone(Static.getBillingAddressFromCustomer(this.getSelectedCustomer).telephone);
      } else if(this.getSelectedShipping==Static.SHOP && this.getRegistrationType==Static.REGISTRATION_TYPE_GUEST) {
        this.setRightNavigation(Static.CART);
      } else {
        this.setSelectedAddress({});
        this.setRightNavigation(Static.SHIPPING_CHOICE);
      }
    },
    addNewShipping(){      
      if(this.getRegistrationType==Static.REGISTRATION_TYPE_USER){
        var urlCustomerWithParameters=this.getUrlSaveAddress.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
        var objectToSend={...{'email':this.getSelectedCustomer.email},...this.getAddressToAdd};
        this.setIsLoadingCustomer(true);
        axios.post(urlCustomerWithParameters,objectToSend)
        .then((res)=>{
          this.setEmptyAddressToAdd();
          this.setSelectedCustomer(res.data[0]);
          this.setSelectedAddress(res.data[0].addresses[res.data[0].addresses.length-1]);
          this.setRightNavigation(Static.SHIPPING_CHOICE);
        })
        .catch(err=>{if(err.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        }})
        .finally(()=>this.setIsLoadingCustomer(false))
      } else {
        this.setSelectedAddress(Static.refactorAddress(this.getAddressToAdd));
        this.addAddressToSelectedCustomer(Static.refactorAddress(this.getAddressToAdd));
        this.setEmptyAddressToAdd();
        this.setRightNavigation(Static.SHIPPING_CHOICE);
      }
    },
    addNewCustomer(){
      var urlCustomerWithParameters=this.getUrlSaveCustomer.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
      this.setIsLoadingCustomer(true);
      axios.post(urlCustomerWithParameters,this.getCustomerToAdd)
        .then(()=>{
          var customerToAdd={...this.getCustomerToAdd};
          customerToAdd.addresses[0]=Static.refactorAddress(this.getCustomerToAdd.addresses[0],true,true);
          this.setSelectedCustomer(customerToAdd);
          this.setSelectedAddress(customerToAdd.addresses[0]);
          this.setEmptyCustomerToAdd();
          this.setRightNavigation(Static.SHIPPING_PLACE);
        })
        .catch(err=>{if(err.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        }})
        .finally(()=>this.setIsLoadingCustomer(false))
    },
    addNewCustomerGuest(){
      var customerToAdd={...this.getCustomerToAdd};
      customerToAdd.telephone=customerToAdd.addresses[0].telephone;
      customerToAdd.addresses=[];
      this.setSelectedCustomer(customerToAdd);
      this.setEmptyCustomerToAdd();
      this.setRightNavigation(Static.SHIPPING_PLACE);
    },
    confirmOrder(){
      var objectToSend=this.prepareOrderObject();
      var urlSaveOrderWithParameters=this.getUrlSaveOrder.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
      this.setIsLoadingCustomer(true);
      if(Static.isLocalhost()) {
        this.setCompletedOrder({...Static.COMPLETED_ORDER_SAMPLE});
        this.setIsLoadingCustomer(false);
        this.setRightNavigation(Static.ORDER_CONFIRMED);
      } else 
        axios.post(urlSaveOrderWithParameters,objectToSend)
        .then((response)=>{
          this.setCompletedOrder(response.data);
          this.sendEmail();
          this.setRightNavigation(Static.ORDER_CONFIRMED);
          this.setIsLoadingCustomer(false)
        }).catch((e)=>{
          if(e.response.status==403){
            this.setNavigation(Static.LOGIN);
            this.deleteUser(this.getUser);
          } else 
            this.$bvToast.toast(e.response.data, {
              title: '',
              autoHideDelay: 5000,
              solid:true,
              variant:'danger'
            });
          this.setIsLoadingCustomer(false)}
        )
    },
    isNotZeroOrNull(value){ return Static.isNotZeroOrNull(value); },
    printReceipt(){
      var dataToPrint={};
      dataToPrint.products=[];
      this.getCart.forEach(product=>{
        var priceToUse=this.getPriceWithSku(product.data.sku)*product.cartQuantity;
        dataToPrint.products.push({
          qty:product.cartQuantity,
          name:product.data.name,
          sku:product.data.sku,
          ean:this.getEANWithSku(product.data.sku),
          eta:this.getDeliveryDateWithSku(product.data.sku),
          total:priceToUse
        })
      })
      dataToPrint.total=this.getCompletedOrder.totalpaid+this.getCompletedOrder.totaldue;
      dataToPrint.totalpaid=this.getCompletedOrder.totalpaid;
      dataToPrint.totaltopay=this.getCompletedOrder.totaldue;
      dataToPrint.id=this.getCompletedOrder.orderid;
      dataToPrint.date=this.getCompletedOrder.date;
      dataToPrint.customerName=this.getSelectedCustomer.firstname+ " "+ this.getSelectedCustomer.lastname;
      dataToPrint.lastDate=Static.getLastDate(dataToPrint.products,'eta');
      dataToPrint.shippingAddress=this.getCompletedOrder.shippingaddress;
      this.setDataToPrint(dataToPrint)
      .then(()=>Static.printReceipt())
    },
    clearSelectedCustomer(){
      this.setCart([]);
      this.setSelectedCustomer(null);
      this.setCartCoupon(Static.EMPTY_COUPON);
      this.setPaymentType('');
      this.setAcconto(0);
      this.setOrderNoteToAdd('');
    },
    fromOrderConfirmedToCart(){
      this.clearSelectedCustomer();
      this.setRightNavigation(Static.CART);
    },
    setTimeRange(){
      var that=this;
      this.setOrderSearchWord('');
      this.setRightNavigation(Static.ORDERS_LABEL);
      if(this.getCancelTokenSearchOrder!=null)this.getCancelTokenSearchOrder.cancel('nuova query');
      this.setCancelTokenSearchOrder(axios.CancelToken.source());
      var urlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace(Static.FROMDATE,'fromdate/'+Static.changeDateFormat(this.getTimeRange.start))
      .replace(Static.TODATE,'todate/'+Static.changeDateFormat(this.getTimeRange.end))
      .replace("/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA/orderid/ID-ORDINE/sku/SKU-PRODOTTO",'')
      .replace(Static.IDNEGOZIO,this.getUser.id);
      this.setIsLoadingCustomers(true);
      axios.get(urlGetOrdersWithParameters,{cancelToken:this.getCancelTokenSearchOrder.token})
      .then((response)=>{ 
        this.setOrderList(Static.refactorOrderList(response.data)); 
        this.setIsLoadingCustomers(false);
      })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) that.setIsLoadingCustomers(false);
        else if(thrown.response.status==403) {
          that.setNavigation(Static.LOGIN);
          that.deleteUser(that.getUser);
          that.setIsLoadingCustomers(false);
      }
      });
    },
    toOrderDetail(){
      this.clearSelectedCustomer();
      this.setNavigation(Static.ORDERS_LABEL);
      this.setRightNavigation(Static.ORDERS_LABEL);
      this.setTimeRange(Static.TODAY_TIME_RANGE);
      var urlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace('/fromdate/ANNO-M-G/todate/ANNO-M-G','')
      .replace(Static.IDNEGOZIO,this.getUser.id);
      urlGetOrdersWithParameters=urlGetOrdersWithParameters.replace(Static.ID_ORDINE,this.getCompletedOrder.orderid);
      this.setIsLoadingCustomers(true);
      axios.get(urlGetOrdersWithParameters)
      .then((response)=> this.setOrderList(Static.refactorOrderList(response.data)) );
    },
    getDeliveryDateWithSku(sku){
      var valueToReturn="";
      this.getCompletedOrder.productdetails.some(item=>{
        if(item.sku.trim()==sku.trim()){
          valueToReturn=item.deliverydate;
          return true;
        }
      })
      return valueToReturn
    },
    getEANWithSku(sku){
      var valueToReturn="";
      this.getCompletedOrder.productdetails.some(item=>{
        if(item.sku.trim()==sku.trim()){
          valueToReturn=item.ean;
          return true;
        }
      })
      return valueToReturn
    },
    getPriceWithSku(sku){
      var valueToReturn="";
      this.getCompletedOrder.productdetails.some(item=>{
        if(item.sku.trim()==sku.trim()){
          valueToReturn=item.price;
          return true;
        }
      })
      return valueToReturn
    },
    prepareOrderObject(){
      var objectToSend={...Static.ORDER_JSON_SAMPLE};
      objectToSend.isguest=this.getRegistrationType==Static.REGISTRATION_TYPE_GUEST;
      objectToSend.firstname=this.getSelectedCustomer.firstname;
      objectToSend.lastname=this.getSelectedCustomer.lastname;
      objectToSend.email=this.getSelectedCustomer.email;
      objectToSend.totaldiscountimport=this.getCartCoupon.import;
      objectToSend.totaldiscountname=this.getCartCoupon.name;
      objectToSend.totalpaid=this.getAcconto;
      objectToSend.comment=this.getOrderNoteToAdd;
      objectToSend.storeid=this.getUser.id;
      var shippingAddress=this.getSelectedShipping==Static.SHOP?this.getUser.address:{...this.getSelectedAddress};
      objectToSend.billingaddress=this.getRegistrationType==Static.REGISTRATION_TYPE_GUEST?{...shippingAddress}:Static.getBillingAddressFromCustomer(this.getSelectedCustomer);
      if(Object.keys(objectToSend.billingaddress).length === 0 && objectToSend.billingaddress.constructor === Object) {
        objectToSend.billingaddress={...shippingAddress};
        objectToSend.billingaddress.firstname=this.getSelectedCustomer.firstname;
        objectToSend.billingaddress.lastname=this.getSelectedCustomer.lastname;
      }
      if(this.getSelectedShipping==Static.SHOP) shippingAddress.telephone=this.getRegistrationType==Static.REGISTRATION_TYPE_GUEST?this.getSelectedCustomer.telephone:this.getTelephone;
      objectToSend.shippingaddress=shippingAddress;
      if(this.getSelectedShipping==Static.HOME)
        (objectToSend.shippingaddress.address?Object.keys(objectToSend.shippingaddress.address):[]).forEach(item=>{
          objectToSend.shippingaddress[item]=objectToSend.shippingaddress.address[item];
        });
      if(this.getSelectedShipping==Static.HOME || this.getRegistrationType==Static.REGISTRATION_TYPE_USER)
        (objectToSend.billingaddress.address?Object.keys(objectToSend.billingaddress.address):[]).forEach(item=>{
          objectToSend.billingaddress[item]=objectToSend.billingaddress.address[item];
        });
      if(this.getRegistrationType==Static.REGISTRATION_TYPE_GUEST){
        objectToSend.billingaddress.firstname=this.getSelectedCustomer.firstname;
        objectToSend.billingaddress.lastname=this.getSelectedCustomer.lastname;
        objectToSend.billingaddress.telephone=this.getSelectedCustomer.telephone;
      }
      delete objectToSend.billingaddress.address;
      delete objectToSend.shippingaddress.address;
      objectToSend.products=[];
      this.getCart.forEach(item=>{
        var itemToUse={...item};
        var productToAdd={};
        productToAdd.id=item.data.id
        productToAdd.qty=item.cartQuantity
        if(itemToUse.header.type=="configurable"){
          itemToUse=this.getSelectedVariantFromProduct(item);
          productToAdd.configurable=[];
          productToAdd.configurable.push({
            "attributecode":itemToUse.configurable_attributes[0],
            "optioncode":itemToUse.data[itemToUse.configurable_attributes[0]]
          })
        }else if(itemToUse.header.type=="bundle"){
          productToAdd.bundle=[];
          Object.keys(itemToUse.selectedBundle).forEach(item=>{
            productToAdd.bundle.push({"productid":itemToUse.selectedBundle[item].data.id})
          })
        }
        if(item.discount){
          productToAdd.rowdiscountimport=item.discount.import;
          productToAdd.rowdiscountname=item.discount.name;
        }
        objectToSend.products.push(productToAdd)
      })
      return objectToSend;
    },
    toPaymentMethod(){
      var objectToSend=this.prepareOrderObject();
      var urlOrderPriceWithParameters=this.getUrlOrderPrice.replace(Static.EMAIL,this.getUser.mail).replace(Static.TOKEN,this.getUser.token);
      this.setAcconto(0);
      this.setIsLoadingCustomer(true);
      if(Static.isLocalhost()) {
        this.setCartTotal(Static.COMPLETED_ORDER_SAMPLE.totaldue);
        this.setCartDiscount(Static.COMPLETED_ORDER_SAMPLE.cartdiscount);
        this.setIsLoadingCustomer(false);
        this.setRightNavigation(Static.PAYMENT_METHOD);
      } else 
      axios.post(urlOrderPriceWithParameters,objectToSend)
      .then((response)=>{
        this.setCartTotal(response.data.totaldue);
        this.setCartDiscount(response.data.cartdiscount?response.data.cartdiscount:0);
        this.setCompletedOrder(response.data);
        this.setRightNavigation(Static.PAYMENT_METHOD);
        this.setIsLoadingCustomer(false);
      })
      .catch((e)=>{
        if(e.response.status==403){
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        } else {
          this.$bvToast.toast(e.response.data, {
            title: '',
            autoHideDelay: 5000,
            solid:true,
            variant:'danger'
          });
        }
        this.setIsLoadingCustomer(false)}
      )
      
    },
    getSelectedVariantFromProduct(product){
      var productToReturn={};
      product.variants.some(variant=>{
        if(variant.data.sku==product.data.sku){
          productToReturn={...variant};
          return true;
        }
      })
      return productToReturn;
    },
    sendEmail(){
      // per mandarla a entrambi mettere 'both'
      var objectToSend={
        idorder:this.getCompletedOrder.orderid,
        emailcopy: this.getUser.mail,
        namecopy: this.getUser.store,
        sendto: "both"
      }
      var getUrlSendEmailWithParameters=this.getUrlSendEmail
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      this.isLoadingSendEmail=true
      axios.post(getUrlSendEmailWithParameters,objectToSend)
      .then(()=>
        this.$bvToast.toast('Email correttamente Inviata', {
          title: '',
          autoHideDelay: 5000,
          solid:true,
          variant:'success'
        })
      )
      .catch(err=>{if(err.response.status==403) {
        this.setNavigation(Static.LOGIN);
        this.deleteUser(this.getUser);
      }})
      .finally(()=>this.isLoadingSendEmail=false)
    },
    customerContinue(){
      var missingInfo=this.getSelectedCustomer.addresses.filter(item=>item.defaultbilling).length>0?lodash.cloneDeep(this.getSelectedCustomer.addresses.filter(item=>item.defaultbilling)[0]).address:{...Static.EMPTY_MISSING_INFO}
      if(lodash.isEqual(this.getMissingInfo,missingInfo)){
        this.setRegistrationType(Static.REGISTRATION_TYPE_USER);
        this.setRightNavigation(Static.SHIPPING_PLACE);
      } else {
        var getUrlSaveBilling=this.getUrlSaveBilling
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        this.setIsLoadingCustomer(true);
        axios.post(getUrlSaveBilling,{email:this.getSelectedCustomer.email,address:{...this.getMissingInfo}})
        .then(()=>{
          var selectedCustomer=lodash.cloneDeep(this.getSelectedCustomer);
          var indexToChange=-1;
          indexToChange=selectedCustomer.addresses.indexOf(selectedCustomer.addresses.filter(item=>item.defaultbilling)[0]);
          if(indexToChange==-1) {
            var addressToAdd={};
            addressToAdd.address={};
            addressToAdd.address=lodash.cloneDeep(this.getMissingInfo);
            addressToAdd.defaultbilling=true;
            addressToAdd.defaultshipping=true;
            addressToAdd.firstname=this.getMissingInfo.firstname;
            addressToAdd.lastname=this.getMissingInfo.lastname;
            addressToAdd.telephone=this.getMissingInfo.telephone;
            selectedCustomer.addresses[0]=lodash.cloneDeep(addressToAdd)
          } else selectedCustomer.addresses[indexToChange].address=lodash.cloneDeep(this.getMissingInfo);
          this.setSelectedCustomer(selectedCustomer);
          this.setMissingInfo({...Static.EMPTY_MISSING_INFO});
          this.setRegistrationType(Static.REGISTRATION_TYPE_USER);
          this.setRightNavigation(Static.SHIPPING_PLACE);
        })
        .catch(err=>{if(err.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        }})
        .finally(()=>this.setIsLoadingCustomer(false))
      }
    }
  }
}
</script>

<style src="./ContainerFooter.css" scoped></style>