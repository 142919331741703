<template>
  <perfect-scrollbar class="containerContentRight" :class="[getRightNavigation,{'with-footer':getCart.length>0 && getRightNavigation==$options.CART}]"
    @ps-y-reach-end="reachEnd" 
    v-if="getRightNavigation!=$options.ORDERS">
    <div class="container" :class="getRightNavigation" v-if="getRightNavigation==$options.CART">
      <div class="placeholder" v-if="getCart.length==0">
        <img class="img" :src="require('@/assets/motorcycle.svg')"/>
        <div class="empty"> Carrello vuoto </div>
        <div class="desc"> Aggiungi un prodotto per effettuare un ordine </div>
      </div>
      <div class="cart" v-else>
        <div class="cart-item" v-for="(product,index) in getCart" :key="'cart_item_'+index">
          <div class="img-container" @click="toModalProduct(product);">
            <div class="discount" v-if="product.discount && product.discount.active"> SCONTO </div>
            <div class="counter"> {{product.cartQuantity}} </div>
            <img :src="Array.isArray(product.data.image)?product.data.image[0]:product.data.image"/>
          </div>
          <div class="info-container">
            <div class="name" @click.stop="">{{product.data.name}}</div>
            <div class="bottom-info">
              <div class="sku" @click.stop="" > SKU: <span> {{product.data.sku}} </span> </div>
              <div class="configuration" v-if="product.size"> Taglia: <span> {{product.size}} </span> </div>
              <div class="price"> {{isNotZeroOrNull(product.pricing['1']['0.0000']['EUR'].selling_price)?'€ '+product.pricing['1']['0.0000']['EUR'].selling_price:'€ '+product.pricing['1']['0.0000']['EUR'].listing_price}} </div>
              <div class="delete" @click.stop="removeFromCart(product.data)"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.FILTER">
      <div class="active-filters-container">
        <div class="active-filter-item" v-for="(activeFilter,index) in getActiveFilters" :key="'active_filter_'+index">
          <div class="value">{{activeFilter.label}}<span>{{" > "+categoriesData(activeFilter.key,activeFilter.value)}}</span></div>
          <div class="remove" @click="removeActiveFilter(activeFilter)"></div>
        </div>
      </div>
      <FilterAccordion type="checkbox" v-if="Object.keys(filterCategoriesWithoutActive).length>1" :filters="getFilterCategories" filterKey="category" label="Categorie"/>
      <FilterAccordion type="checkbox" v-if="Object.keys(filterManufacturersWithoutActive).length>1" :filters="getFilterManufacturers" filterKey="manufacturer" label="Marca"/>
      <FilterAccordion type="slider" v-if="getFilterPrices.min!==getFilterPrices.max" :filters="getFilterPrices" filterKey="selling_price" label="Prezzo"/>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.CUSTOMERS_LIST">
      <div class="spinner" v-if="getIsLoadingCustomers">
        <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
      </div>
      <div class="customers-container" v-if="getCustomersList.length>0">
        <div class="customer-item" v-for="(customer,index) in getCustomersList" :key="'customers_'+index" :class="{active:getSelectedCustomer!=null && getSelectedCustomer.email==customer.email,inactive:getSelectedCustomer!=null && getSelectedCustomer.email!=customer.email,favorite:customer.priority}" 
          @click="customerClick(customer)">
          <div class="info">
            <div class="name">{{customer.firstname +" "+customer.lastname}}</div>
            <div class="mail">{{customer.email}}</div>
          </div>
          <div class="select"></div>
        </div>
      </div>
      <div class="customers-loading" v-else>
        Caricando la lista clienti
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.CUSTOMER_CHOICE">
      <div class="subHeader">
        <div class="customer-name"> {{ getSelectedCustomer.firstname +' '+ getSelectedCustomer.lastname}} </div>
        <div class="icon"></div>
      </div>
      <div class="content">
        <div class="info-container">
          <div class="title"> Informazioni Account </div>
          <div class="info"> {{getSelectedCustomer.firstname + ' ' + getSelectedCustomer.lastname}} </div>
          <div class="info"> {{getSelectedCustomer.email}} </div>
          <div class="info"> {{getSelectedCustomer.telephone}} </div>
        </div>
        <div class="info-container" v-if="getDefaultBilling && getDefaultBilling.address">
          <div class="title"> Indirizzo di Fatturazione </div>
          <div class="info"> {{getDefaultBilling.firstname + ' ' + getDefaultBilling.lastname}} </div>
          <div class="info"> {{getDefaultBilling.address.streetname +' '+ getDefaultBilling.address.streetnumber +', ' + getDefaultBilling.address.city+ ', '+ getDefaultBilling.address.postcode + ', '+ getDefaultBilling.address.country}} </div>
          <div class="info"> {{getDefaultBilling.telephone}} </div>
        </div>
        <div class="info-container" v-else>
          <div class="title"> Indirizzo di Fatturazione </div>
          <div class="info"> Non presente </div>
        </div>
        <div class="missing-fields">
          <div class="title" v-if="isMissingInfo"> Per continuare valorizza i seguenti campi </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('firstname')" :state="!!getMissingInfo.firstname && getMissingInfo.firstname!=''" class="firstname" :value="getMissingInfo.firstname" @input="setMissingInfoByType({value:$event,type:'firstname'})" placeholder="Nome*" > </b-form-input>
            <b-form-input v-if="missingFieldCheck('lastname')" :state="!!getMissingInfo.lastname && getMissingInfo.lastname!=''" class="lastname" :value="getMissingInfo.lastname" @input="setMissingInfoByType({value:$event,type:'lastname'})" placeholder="Cognome*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-select v-if="missingFieldCheck('country')" :state="!!getMissingInfo.country && getMissingInfo.country!=''" class="country" :value="getMissingInfo.country" :options="$options.COUNTRY_LIST" @input="setMissingInfoByType({value:$event,type:'country'})" placeholder="Country*"> </b-form-select>
            <b-form-input v-if="missingFieldCheck('region')" :state="!!getMissingInfo.region && getMissingInfo.region!=''" class="city" :value="getMissingInfo.region" @input="setMissingInfoByType({value:$event,type:'region'})" placeholder="Provincia*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('city')" :state="!!getMissingInfo.city && getMissingInfo.city!=''" class="city" :value="getMissingInfo.city" @input="setMissingInfoByType({value:$event,type:'city'})" placeholder="Città*"> </b-form-input>
            <b-form-input v-if="missingFieldCheck('postcode')" :state="!!getMissingInfo.postcode && getMissingInfo.postcode!=''" type="number" class="postcode" :value="getMissingInfo.postcode" @input="setMissingInfoByType({value:$event,type:'postcode'})" placeholder="CAP*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('telephone')" :state="!!getMissingInfo.telephone && getMissingInfo.telephone!=''" class="telephone" :value="getMissingInfo.telephone" @input="setMissingInfoByType({value:$event,type:'telephone'})" placeholder="Telefono*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('streetname')" :state="!!getMissingInfo.streetname && getMissingInfo.streetname!=''" class="streetname" :value="getMissingInfo.streetname" @input="setMissingInfoByType({value:$event,type:'streetname'})" placeholder="Indirizzo*"> </b-form-input>
          </div>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.NEW_CUSTOMER">
      <div class="content">
        <div class="search-user">
          <div class="label"> Inserisci l’indirizzo Email </div>
          <b-form-input
            class="customer-search"
            :value="getUserSearchWord"
            placeholder="Mail"
            @keyup.native="onCustomerSearchWordInput"> 
          </b-form-input>
          <span class="loading" v-if="getIsLoadingCustomer"> 
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> 
          </span>
        </div>
        <div class="user-info" v-if="getSelectedCustomer">
          <div> Questo indirizzo email è già associato ad un account. </div>
          <div class="customer-name-container">
            <div class="customer-name"> {{ getSelectedCustomer.firstname +' '+ getSelectedCustomer.lastname}} </div>
            <div class="icon"></div>
          </div>
          <div class="info-container">
            <div class="title"> Informazioni Account </div>
            <div class="info"> {{getSelectedCustomer.firstname + ' ' + getSelectedCustomer.lastname}} </div>
            <div class="info"> {{getSelectedCustomer.email}} </div>
            <div class="info"> {{getSelectedCustomer.telephone}} </div>
          </div>
          <div class="info-container" v-if="getDefaultBilling && getDefaultBilling.address">
            <div class="title"> Indirizzo di Fatturazione </div>
            <div class="info"> {{getDefaultBilling.firstname + ' ' + getDefaultBilling.lastname}} </div>
            <div class="info" > {{getDefaultBilling.address.streetname +' '+ getDefaultBilling.address.streetnumber + ', ' + getDefaultBilling.address.city+ ', '+ getDefaultBilling.address.postcode + ', '+ getDefaultBilling.address.country}} </div>
            <div class="info"> {{getDefaultBilling.telephone}} </div>
          </div>
          <div class="info-container" v-else>
            <div class="title"> Indirizzo di Fatturazione </div>
            <div class="info"> Non presente </div>
          </div>
        </div>
        <div v-else-if="!getSelectedCustomer && getUserSearchWord.trim().length>0 && !getIsLoadingCustomer">
          Questo indirizzo non è ancora associato ad un account.
        </div>
        <div class="missing-fields" v-if="getSelectedCustomer">
          <div class="title" v-if="isMissingInfo"> Per continuare valorizza i seguenti campi </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('firstname')" :state="!!getMissingInfo.firstname && getMissingInfo.firstname!=''" class="firstname" :value="getMissingInfo.firstname" @input="setMissingInfoByType({value:$event,type:'firstname'})" placeholder="Nome*" > </b-form-input>
            <b-form-input v-if="missingFieldCheck('lastname')" :state="!!getMissingInfo.lastname && getMissingInfo.lastname!=''" class="lastname" :value="getMissingInfo.lastname" @input="setMissingInfoByType({value:$event,type:'lastname'})" placeholder="Cognome*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-select v-if="missingFieldCheck('country')" :state="!!getMissingInfo.country && getMissingInfo.country!=''" class="country" :value="getMissingInfo.country" :options="$options.COUNTRY_LIST" @input="setMissingInfoByType({value:$event,type:'country'})" placeholder="Country*"> </b-form-select>
            <b-form-input v-if="missingFieldCheck('region')" :state="!!getMissingInfo.region && getMissingInfo.region!=''" class="city" :value="getMissingInfo.region" @input="setMissingInfoByType({value:$event,type:'region'})" placeholder="Provincia*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('city')" :state="!!getMissingInfo.city && getMissingInfo.city!=''" class="city" :value="getMissingInfo.city" @input="setMissingInfoByType({value:$event,type:'city'})" placeholder="Città*"> </b-form-input>
            <b-form-input v-if="missingFieldCheck('postcode')" :state="!!getMissingInfo.postcode && getMissingInfo.postcode!=''" type="number" class="postcode" :value="getMissingInfo.postcode" @input="setMissingInfoByType({value:$event,type:'postcode'})" placeholder="CAP*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('telephone')" :state="!!getMissingInfo.telephone && getMissingInfo.telephone!=''" class="telephone" :value="getMissingInfo.telephone" @input="setMissingInfoByType({value:$event,type:'telephone'})" placeholder="Telefono*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-input v-if="missingFieldCheck('streetname')" :state="!!getMissingInfo.streetname && getMissingInfo.streetname!=''" class="streetname" :value="getMissingInfo.streetname" @input="setMissingInfoByType({value:$event,type:'streetname'})" placeholder="Indirizzo*"> </b-form-input>
          </div>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.SHIPPING_PLACE">
      <div class="content">
        <SquareButton label="A Casa" @click.native="setSelectedShipping($options.HOME);" :selected="getSelectedShipping==$options.HOME"/>
        <SquareButton label="In Negozio" @click.native="setSelectedShipping($options.SHOP);" :selected="getSelectedShipping==$options.SHOP"/>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.ONLY_TELEPHONE">
      <div class="content">
        <div class="row">
          <b-form-input :state="getTelephone!=''" class="telephone" :value="getTelephone" @input="setTelephone($event)" placeholder="Nuovo num telefono*"> </b-form-input>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.SHIPPING_CHOICE">
      <div class="content">
        <AddressAccordion v-for="(address,index) in getSelectedCustomer.addresses" :key="'address_'+index" :address="address" :index="index"/>
        <div @click="setRightNavigation($options.NEW_SHIPPING)" class="new-address"> + Aggiungi Nuovo Indirizzo </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.SAVE_NEW_CUSTOMER">
      <div class="content">
        <div>
          <div class="row">
            <b-form-input class="firstname" :state="getCustomerToAdd.firstname!=''" :value="getCustomerToAdd.firstname" @input="setCustomerToAdd({value:$event,type:'firstname'});setCustomerAddressToAdd({value:$event,type:'firstname'})" placeholder="Nome*" > </b-form-input>
            <b-form-input class="lastname" :state="getCustomerToAdd.lastname!=''" :value="getCustomerToAdd.lastname" @input="setCustomerToAdd({value:$event,type:'lastname'});setCustomerAddressToAdd({value:$event,type:'lastname'})" placeholder="Cognome*"> </b-form-input>
          </div>
          <div class="row">
            <b-form-input class="email" :state="getCustomerToAdd.email!='' && $options.EMAIL_REGEX.test(getCustomerToAdd.email) && (getIsValidMail || getRegistrationType==$options.REGISTRATION_TYPE_GUEST)" :value="getCustomerToAdd.email" @input="setEmail($event)" placeholder="Email*"> </b-form-input>
            <div class="invalid-email" v-if="!getIsValidMail"> Questa email è già usata </div>
          </div>
          <div class="row">
            <b-form-input v-if="getRegistrationType==$options.REGISTRATION_TYPE_USER" type="password" :state="getCustomerToAdd.password.length>6" class="password" :value="getCustomerToAdd.password" @input="setCustomerToAdd({value:$event,type:'password'})" placeholder="Pwd* (7 caratteri)"> </b-form-input>
            <b-form-input :state="getCustomerToAdd.addresses[0].telephone!=''" class="telephone" :value="getCustomerToAdd.addresses[0].telephone" @input="setCustomerAddressToAdd({value:$event,type:'telephone'})" placeholder="Telefono*"> </b-form-input>
          </div>
        </div>
        <b-form-checkbox :checked="getAcceptedGDPR" @input="setAcceptedGDPR($event)" class="checkbox"> Ho letto e accetto l' <a href="https://www.motoabbigliamento.it/informativa-privacy" target="_blank"> informativa sulla privacy</a> e le <a href="https://www.motoabbigliamento.it/condizioni-di-vendita" target="_blank"> condizioni di vendita </a> </b-form-checkbox>
        <div class="addressAccordion" v-if="getRegistrationType==$options.REGISTRATION_TYPE_USER">
          <div class="title" @click="showAccordion=!showAccordion"> Indirizzo di Fatturazione
            <div class="icon" :class="showAccordion?'expanded':'collapsed'"></div>
          </div>
          <b-collapse v-model="showAccordion" class="info-collapse">
              <div class="row">
                <b-form-input :state="getCustomerToAdd.addresses[0].firstname!=''" class="firstname" :value="getCustomerToAdd.addresses[0].firstname" @input="setCustomerAddressToAdd({value:$event,type:'firstname'})" placeholder="Nome*" > </b-form-input>
                <b-form-input :state="getCustomerToAdd.addresses[0].lastname!=''" class="lastname" :value="getCustomerToAdd.addresses[0].lastname" @input="setCustomerAddressToAdd({value:$event,type:'lastname'})" placeholder="Cognome*"> </b-form-input>
              </div>
              <div class="row">
                <b-form-input class="company" :value="getCustomerToAdd.addresses[0].company" @input="setCustomerAddressToAdd({value:$event,type:'company'})" placeholder="Società"> </b-form-input>
                <b-form-select :state="getCustomerToAdd.addresses[0].country!=''" class="country" :value="getCustomerToAdd.addresses[0].country" :options="$options.COUNTRY_LIST" @input="setCustomerAddressToAdd({value:$event,type:'country'})" placeholder="Country*"> </b-form-select>
              </div>
              <div class="row">
                <b-form-input :state="getCustomerToAdd.addresses[0].city!=''" class="city" :value="getCustomerToAdd.addresses[0].city" @input="setCustomerAddressToAdd({value:$event,type:'city'})" placeholder="Città*"> </b-form-input>
                <b-form-input :state="getCustomerToAdd.addresses[0].postcode!=''" type="number" class="postcode" :value="getCustomerToAdd.addresses[0].postcode" @input="setCustomerAddressToAdd({value:$event,type:'postcode'})" placeholder="CAP*"> </b-form-input>
              </div>
              <div class="row">
                <b-form-input :state="getCustomerToAdd.addresses[0].region!=''" class="city" :value="getCustomerToAdd.addresses[0].region" @input="setCustomerAddressToAdd({value:$event,type:'region'})" placeholder="Provincia*"> </b-form-input>
              </div>
              <div class="row">
                <b-form-input :state="getCustomerToAdd.addresses[0].streetname!=''" class="streetname" :value="getCustomerToAdd.addresses[0].streetname" @input="setCustomerAddressToAdd({value:$event,type:'streetname'})" placeholder="Indirizzo*"> </b-form-input>
              </div>
          </b-collapse>
        </div>
        <div class="registration-type-container">
          <SquareButton label="Registrazione" @click.native="setRegistrationType($options.REGISTRATION_TYPE_USER);setEmail(getCustomerToAdd.email);" :selected="getRegistrationType==$options.REGISTRATION_TYPE_USER"/>
          <SquareButton label="Ospite" @click.native="setRegistrationType($options.REGISTRATION_TYPE_GUEST);setEmail(getCustomerToAdd.email);" :selected="getRegistrationType==$options.REGISTRATION_TYPE_GUEST"/>
        </div>
        <div class="vantaggi">
          <div class="tutti"> Tutti i vantaggi di Motoabbigliamento </div>
          <div class="list">
            • Ordinare online <br>
            • Uso delle Gift Card <br>
            • Resta aggiornato su tutte le offerte <br>
            • Sorteggi e premi in palio <br>
            • Accesso anticipato agli sconti <br>
          </div>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.NEW_SHIPPING">
      <div class="content">
        <div class="row">
          <b-form-input class="firstname" :state="getAddressToAdd.firstname!=''" :value="getAddressToAdd.firstname" @input="setAddressToAdd({value:$event,type:'firstname'})" placeholder="Nome*" > </b-form-input>
          <b-form-input class="lastname" :state="getAddressToAdd.lastname!=''" :value="getAddressToAdd.lastname" @input="setAddressToAdd({value:$event,type:'lastname'})" placeholder="Cognome*"> </b-form-input>
        </div>
        <div class="row">
          <b-form-input class="company" :value="getAddressToAdd.company" @input="setAddressToAdd({value:$event,type:'company'})" placeholder="Società"> </b-form-input>
          <b-form-input class="telephone" :state="getAddressToAdd.telephone!=''" :value="getAddressToAdd.telephone" @input="setAddressToAdd({value:$event,type:'telephone'})" placeholder="Telefono*"> </b-form-input>
        </div>
        <div class="row">
          <b-form-input class="streetname" :state="getAddressToAdd.streetname!=''" :value="getAddressToAdd.streetname" @input="setAddressToAdd({value:$event,type:'streetname'})" placeholder="Indirizzo*"> </b-form-input>
        </div>
        <div class="row">
          <b-form-input class="city" :state="getAddressToAdd.city!=''" :value="getAddressToAdd.city" @input="setAddressToAdd({value:$event,type:'city'})" placeholder="Città*"> </b-form-input>
          <b-form-input class="postcode" type="number" :state="getAddressToAdd.postcode!=''" :value="getAddressToAdd.postcode" @input="setAddressToAdd({value:$event,type:'postcode'})" placeholder="CAP*"> </b-form-input>
        </div>
        <div class="row">
          <b-form-input class="city" :state="getAddressToAdd.region!=''" :value="getAddressToAdd.region" @input="setAddressToAdd({value:$event,type:'region'})" placeholder="Provincia*"> </b-form-input>
          <b-form-select class="country" :state="getAddressToAdd.country!=''" :value="getAddressToAdd.country" :options="$options.COUNTRY_LIST" @input="setAddressToAdd({value:$event,type:'country'})" placeholder="Country*"> </b-form-select>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.NEW_COUPON">
      <div class="content">
        <div class="search-coupon">
          <div class="label"> Inserisci il codice </div>
          <b-form-input
            class="coupon-search"
            :value="couponSearchWord"
            placeholder="Codice Coupon"
            @keyup.native="onCouponSearchWordInput">
          </b-form-input>
          <span class="loading" v-if="getIsLoadingCoupon"> 
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner> 
          </span>
        </div>
        <div class="coupon-info" v-if="getCouponToAdd.active">
          <div> Questo Coupon vale <span class="value"> {{ getCouponToAdd.import + (getCouponToAdd.import && getCouponToAdd.import.includes('%')?'':'€') }} </span> </div>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.NOTE">
      <div class="content">
        <b-form-textarea
          :value="getOrderNoteToAdd"
          no-resize
          placeholder="Scrivi qui delle note rilevanti per l’ordine…"
          @keyup.native="(evt)=>{setOrderNoteToAdd(evt.target.value)}">
        </b-form-textarea>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.CUSTOM_COUPON">
      <div class="content">
        <div class="custom-coupon">
          <div class="label"> Dai un Nome a questo Sconto </div>
          <b-form-input class="coupon-name" :state="getCouponToAdd.name!=''" :value="getCouponToAdd.name" placeholder="Nome Coupon" @input="setCouponToAdd({type:'name',value:$event})"> </b-form-input>
          <div class="coupon-value-container">
            <b-form-input class="coupon-value" :state="parseFloat(getCouponToAdd.import)>0" :value="parseFloat(getCouponToAdd.import)" @input="setCouponToAdd({type:'import',value:parseFloat($event)+(couponType==$options.PERCENT?$options.PERCENT:'')})"> </b-form-input>
            <div class="coupon-value-type-container">
              <div class="coupon-value-type" :class="{selected:couponType==$options.EURO}" @click="couponType=$options.EURO;setCouponToAdd({type:'import',value:getCouponToAdd.import.replace('%','')})"> € </div>
              <div class="coupon-value-type" :class="{selected:couponType==$options.PERCENT}" @click="couponType=$options.PERCENT;setCouponToAdd({type:'import',value:getCouponToAdd.import+'%'})"> % </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.PAYMENT_METHOD">
      <div class="content">
        <div class="payment-type-container">
          <SquareButton :label="$options.PAYMENT_TYPE_PAID" :selected="getPaymentType==$options.PAYMENT_TYPE_PAID" @click.native="setPaymentType($options.PAYMENT_TYPE_PAID);setAcconto(getCartTotal)"/>
          <SquareButton :label="$options.PAYMENT_TYPE_TO_PAY" :selected="getPaymentType==$options.PAYMENT_TYPE_TO_PAY" @click.native="setPaymentType($options.PAYMENT_TYPE_TO_PAY);setAcconto(0)"/>
          <SquareButton class="acconto" :label="$options.PAYMENT_TYPE_ACCONTO" :selected="false" @click.native="setPaymentType('');setAcconto(0);setRightNavigation($options.ACCONTO)"/>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.ACCONTO">
      <div class="content">
        <div class="acconto-type-container">
          <SquareButton label="+5" :selected="false" :class="{inactive:getCartTotal-getAcconto-5<0}" @click.native="setAcconto(getAcconto+5);"/>
          <SquareButton label="+10" :selected="false" :class="{inactive:getCartTotal-getAcconto-10<0}" @click.native="setAcconto(getAcconto+10);"/>
          <SquareButton label="+20" :selected="false" :class="{inactive:getCartTotal-getAcconto-20<0}" @click.native="setAcconto(getAcconto+20);"/>
          <SquareButton label="+50" :selected="false" :class="{inactive:getCartTotal-getAcconto-50<0}" @click.native="setAcconto(getAcconto+50);"/>
        </div>
        <b-form-input class="acconto-value" :state="getAcconto>0 && getCartTotal-getAcconto>=0 " :value="getAcconto" @input="setAcconto($event!=''?parseFloat($event):0)"> </b-form-input>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.ORDER_CONFIRMED">
      <div class="content">
        <div class="customer-info">
          <div class="name"> {{getSelectedCustomer.firstname + " " + getSelectedCustomer.lastname}}</div>
          <div class="mail"> {{getSelectedCustomer.email}} </div>
        </div>
        <div class="order-id">
          <div class="success-icon"></div>
          <div class="value"> {{"#"+getCompletedOrder.orderid}} </div>
        </div>
        <div class="acconto-info">
          <div class="row"> 
            <div class="label"> Totale Ordine </div>
            <div class="value"> {{"€ "+(getCompletedOrder.totalpaid+getCompletedOrder.totaldue)}} </div> 
          </div>
          <div class="row"> 
            <div class="label"> Acconto </div>
            <div class="value"> {{"- € " +(getCompletedOrder.totalpaid?getCompletedOrder.totalpaid:0)}} </div> 
          </div>
          <div class="row"> 
            <div class="label"> Totale da pagare </div>
            <div class="value"> {{"€ "+(getCompletedOrder.totaldue?getCompletedOrder.totaldue:0)}} </div> 
          </div>
        </div>
      </div>
    </div>
    <div class="container" :class="getRightNavigation" v-else-if="getRightNavigation==$options.CALENDAR">
      <div class="content">
        <v-date-picker
          mode="range"
          :value="getTimeRange"
          :locale="{ id: 'it-IT', firstDayOfWeek: 2}" is-expanded
          is-inline
          :disabled-dates="{start:tomorrow,end:null}"
          color='gray'
          @input="setTimeRange"/>
      </div>
    </div>
  </perfect-scrollbar>
  <div class="containerContentRight" :class="getRightNavigation" v-else>
    <div class="container" :class="getRightNavigation">
      <div class="time-container"> 
        <div v-for="(item,index) in Array(2).fill().map((x,i)=>1-i)" :key="'time_item_'+index" class="label" 
          :class="{selected:isEqualTimeRange(getTimeRange,getTimeRangeFromIndex(item))}"
          @click="onMonthClick(item)">
          {{ getMonthName(item) }}
        </div>
        <div class="label" :class="{selected:isEqualTimeRange(getTimeRange,getTimeRangeFromDays(60))}" @click="onDaysClick(60)">
          -60GG
        </div>
        <div class="label" :class="{selected:isEqualTimeRange(getTimeRange,getTimeRangeFromDays(30))}" @click="onDaysClick(30)">
          -30GG
        </div>
        <div class="label" :class="{selected:isEqualTimeRange(getTimeRange,$options.ALL_TIME_RANGE)}" @click="onAllClick()">
          TUTTI
        </div>
        <div class="label calendar-icon" @click="calendarIconClick()" :class="{selected:$options.NULL_TIME_RANGE}"> </div>
      </div>
      <div class="filters-container">
        <div class="status-container">
          <div class="item" v-for="(item,index) in $options.STATUS_ARRAY" :key="'status_item_'+index"
            @click="filterClick(item,'status')" :class="[item.key,{selected:$options.checkKey(orderFilters['status'],item)!=-1}]">
            <div class="icon" :class="item.key"></div>
            <div class="label"> {{item.label}} </div>
          </div>
        </div>
        <div class="etichette-container">
          <div class="item" v-for="(item,index) in $options.ETICHETTE_ARRAY" :key="'etichetta_item_'+index" 
            :class="['key_'+item.key,{selected:$options.checkKey(orderFilters['label'],item)!=-1}]" @click="filterClick(item,'label')">
            <div class="icon" :class="'key_'+item.key"></div>
            <div class="label"> {{item.label}} </div>
          </div>
        </div>
      </div>
      <div class="order-list-container">
        <perfect-scrollbar>
          <div class="spinner" v-if="getIsLoadingCustomers">
            <b-spinner variant="primary" type="grow" label="Spinning"></b-spinner>
          </div>
          <div class="orders-container" v-if="Object.keys(getFormattedOrderList).length>0">
            <OrderAccordion class="date-division-item" v-for="(date,index) in Object.keys(getFormattedOrderList).sort().reverse()" :key="'date_division_'+index" 
              :index="index" :date="date" :getFormattedOrderList="getFormattedOrderList"/>
          </div>
          <div class="customers-loading" v-else-if="getIsLoadingCustomers"> Caricamento Ordini </div>
          <div class="customers-loading" v-else> Non ci sono ordini </div>
        </perfect-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters,mapActions } from 'vuex';
import axios from 'axios';
import Static from '@/utils/Static';
import FilterAccordion from '@/components/filterAccordion/FilterAccordion';
import SquareButton from '@/components/squareButton/SquareButton';
import AddressAccordion from '@/components/addressAccordion/AddressAccordion';
import OrderAccordion from '@/components/orderAccordion/OrderAccordion';

export default {
  name: 'containerContentRight',
  CART:Static.CART,
  FILTER:Static.FILTER,
  CUSTOMERS_LIST:Static.CUSTOMERS_LIST,
  CUSTOMER_CHOICE:Static.CUSTOMER_CHOICE,
  SHIPPING_PLACE:Static.SHIPPING_PLACE,
  SHIPPING_CHOICE:Static.SHIPPING_CHOICE,
  NEW_SHIPPING:Static.NEW_SHIPPING,
  NEW_CUSTOMER:Static.NEW_CUSTOMER,
  SAVE_NEW_CUSTOMER:Static.SAVE_NEW_CUSTOMER,
  EMPTY_ADDRESS:Static.EMPTY_ADDRESS,
  COUNTRY_LIST:Static.COUNTRY_LIST,
  LOGIN:Static.LOGIN,
  REGISTRATION_TYPE_GUEST:Static.REGISTRATION_TYPE_GUEST,
  ONLY_TELEPHONE:Static.ONLY_TELEPHONE,
  REGISTRATION_TYPE_USER:Static.REGISTRATION_TYPE_USER,
  EMAIL_REGEX:Static.EMAIL_REGEX,
  SHOP:Static.SHOP,
  NEW_COUPON:Static.NEW_COUPON,
  CUSTOM_COUPON:Static.CUSTOM_COUPON,
  EMPTY_COUPON:Static.EMPTY_COUPON,
  HOME:Static.HOME,
  EURO:Static.EURO,
  PERCENT:Static.PERCENT,
  PAYMENT_METHOD:Static.PAYMENT_METHOD,
  ORDER_CONFIRMED:Static.ORDER_CONFIRMED,
  ACCONTO:Static.ACCONTO,
  PAYMENT_TYPE_PAID:Static.PAYMENT_TYPE_PAID,
  PAYMENT_TYPE_ACCONTO:Static.PAYMENT_TYPE_ACCONTO,
  PAYMENT_TYPE_TO_PAY:Static.PAYMENT_TYPE_TO_PAY,
  NOTE:Static.NOTE,
  ORDERS:Static.ORDERS_LABEL,
  CALENDAR:Static.CALENDAR,
  NULL_TIME_RANGE:Static.NULL_TIME_RANGE,
  ALL_TIME_RANGE:Static.ALL_TIME_RANGE,
  STATUS_ARRAY:Static.STATUS_ARRAY,
  ETICHETTE_ARRAY:Static.ETICHETTE_ARRAY,
  checkKey:Static.checkKey,
  components:{FilterAccordion,SquareButton,AddressAccordion,OrderAccordion},
  data(){
    return{
      couponSearchWord:'',
      cancelToken:null,
      cancelTokenCoupon:null,
      showAccordion:false,
      couponType:Static.EURO,
      orderFilters:{...Static.EMPTY_ORDER_FILTERS},
    }
  },
  computed:{
    ...mapGetters(['getRightNavigation','getFilterCategories','getFilterManufacturers','getFilterPrices','getActiveFilters','getCart'
      ,'getCustomersList','getSelectedCustomer','getIsLoadingCustomers','getSelectedShipping','getSearchUrl','getAddressToAdd','getUrlCustomer'
      ,'getPaymentType','getTotal','getAcconto','getTotalToPay','getCompletedOrder','getOrderNoteToAdd','getTimeRange','getOrderList','getUrlGetOrders'
      ,'getCancelTokenSearchOrder','getCartRules','getUserSearchWord','getAcceptedGDPR','getCartTotal','getCustomersPage','getCustomerSearchWord'
      ,'getUrlCustomers','getIsValidMail','getTelephone','getMissingInfo'
      ,'getUser','getIsLoadingCustomer','getCustomerToAdd','getRegistrationType','getIsLoadingCoupon','getUrlCheckCoupon','getCouponToAdd']),
    getDefaultBilling(){
      return this.getSelectedCustomer.addresses.filter(item=>item.defaultbilling)[0]
    },
    filterCategoriesWithoutActive(){
      return Static.filtersWithoutActive(this.getActiveFilters,this.getFilterCategories,"category");
    },
    filterManufacturersWithoutActive(){
      return Static.filtersWithoutActive(this.getActiveFilters,this.getFilterManufacturers,"category");
    },
    tomorrow(){
      const tomorrow = new Date();
      tomorrow.setDate(tomorrow.getDate() + 1);
      return tomorrow
    },
    getFormattedOrderList(){
      var objectToReturn={};
      var filteredOrderList=[...this.getOrderList];
      if(this.orderFilters['status'].length>0 || this.orderFilters['label'].length>0){
        if(this.orderFilters['status'].length>0){ 
          filteredOrderList=filteredOrderList.filter(order=>
            Static.checkKeyWithValue(this.orderFilters['status'],order.status)!=-1
          )
        } 
        if(this.orderFilters['label'].length>0) {
          filteredOrderList=filteredOrderList.filter(order=>{
            var check=false;
            order.items.forEach(product=>{
              var productLabel=product.label;
              if(!product.label) productLabel=0;
              // if(product.label==4) productLabel=1;
              if(Static.checkKeyWithValue(this.orderFilters['label'],productLabel)!=-1){
                check=true;
                return false;
              }
            }
          )
          return check;
          })
        }
      }
      filteredOrderList.forEach(item=>{
        var dateKey=item.date.split(" ")[0];
        if(!objectToReturn[dateKey]) objectToReturn[dateKey]=[];
        objectToReturn[dateKey].push(item);
      })
      return objectToReturn;
    },
    isMissingInfo(){
      return this.missingFieldCheck('firstname') || this.missingFieldCheck('lastname') || this.missingFieldCheck('city') || 
      this.missingFieldCheck('country') || this.missingFieldCheck('postcode') || this.missingFieldCheck('telephone') ||
      this.missingFieldCheck('streetname') || this.missingFieldCheck('region')
    }
  },methods:{
    ...mapActions({removeFilter:'removeActiveFilter'}),
    ...mapActions(['removeFromCart','setSelectedCustomer','setSelectedShipping','setDataFromResponse','setRightNavigation','setAddressToAdd'
      ,'setShowProductModal','setShowProductModalWithDesc','setShowProductModalFromCart','setPaymentType','setAcconto','setOrderNoteToAdd'
      ,'setCustomersPage','setCustomersList','setIsValidMail','deleteUser','setNavigation','setTelephone','setMissingInfoByType'
      ,'setTimeRange','setOrderList','setIsLoadingCustomers','setCancelTokenSearchOrder','setOrderSearchWord','setUserSearchWord','setAcceptedGDPR'
      ,'setIsLoadingCustomer','setRegistrationType','setCustomerAddressToAdd','setCustomerToAdd','setIsLoadingCoupon','setCouponToAdd','setSelectedProduct']),
    isNotNullOrEmpty(value){
      Static.isNotNullOrEmpty(value);
    },
    toModalProduct(product){
      this.setSelectedProduct(product);
      this.setShowProductModalFromCart(true);
      this.setShowProductModalWithDesc(false);
      this.setShowProductModal(true);
    },
    removeActiveFilter(activeFilter){
      this.removeFilter(activeFilter);
      axios.get(this.getSearchUrl)
        .then(response => { this.setDataFromResponse(response.data) } )
        .catch(() => { } );
    },
    customerClick(customer){
      if(this.getSelectedCustomer!=null && this.getSelectedCustomer.email==customer.email)
        this.setSelectedCustomer(null);
      else
        this.setSelectedCustomer(customer);
    },
    categoriesData(key,value){return key==='category'?value.split('/')[value.split('/').length-1]:value},
    isNotZeroOrNull(value){ return Static.isNotZeroOrNull(value); },
    onCustomerSearchWordInput(evt){
      if(this.cancelToken!=null)this.cancelToken.cancel('nuova query');
      this.cancelToken=axios.CancelToken.source();
      this.setUserSearchWord(evt.target.value);
      if(evt.target.value.trim()!=''){
        this.setIsLoadingCustomer(true);
        var urlCustomerWithParameters=this.getUrlCustomer
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace('/phone/CUSTOMER-PHONE/info/CUSTOMER-FIRSTNAME-LASTNAME','')
        .replace(Static.CUSTOMER_EMAIL,encodeURIComponent(this.getUserSearchWord.replace(' ','')));
        axios.get(urlCustomerWithParameters,{cancelToken:this.cancelToken.token})
        .then((res)=>{
          if(res.data[0])
            this.setSelectedCustomer(res.data[0]);
          else{
            this.setSelectedCustomer(null);
            this.setCustomerToAdd({value:this.getUserSearchWord,type:'email'})
          }
          this.setIsLoadingCustomer(false);
        })
        .catch(function (thrown) {
          if (!axios.isCancel(thrown)) this.setIsLoadingCustomer(false);
          else if(thrown.response.status==403){
            this.setNavigation(Static.LOGIN);
            this.deleteUser(this.getUser);
            this.setIsLoadingCustomer(false);
          }
        });
      } else
        this.setIsLoadingCustomer(false);
    },
    onCouponSearchWordInput(evt){
      if(this.cancelTokenCoupon!=null)this.cancelTokenCoupon.cancel('nuova query');
      this.cancelTokenCoupon=axios.CancelToken.source();
      this.setIsLoadingCoupon(true);
      this.couponSearchWord=evt.target.value;
      var urlCheckCouponWithParameters=this.getUrlCheckCoupon
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace(Static.COUPON_CODE,this.couponSearchWord.replace(' ',''));
      axios.get(urlCheckCouponWithParameters,{cancelToken:this.cancelTokenCoupon.token})
        .then((res)=>{
          if(res.data.active)
            {
              this.setCouponToAdd({type:'import',value:res.data.import});
              this.setCouponToAdd({type:'name',value:res.data.name});
              this.setCouponToAdd({type:'active',value:true});
            }
          else this.setCouponToAdd(Static.EMPTY_COUPON);
          this.setIsLoadingCoupon(false);
        })
        .catch(function (thrown) {
          if (!axios.isCancel(thrown)) this.setIsLoadingCoupon(false);
          else if(thrown.response.status==403) {
            this.setNavigation(Static.LOGIN);
            this.deleteUser(this.getUser);
            this.setIsLoadingCoupon(false);
        }});
    },
    getMonthName(index){
      var dateToReturn = new Date();
      dateToReturn.setMonth(Static.getMonthIndex(index));
      return dateToReturn.toLocaleString("it-IT", {month: "long"}).substring(0, 3);
    },
    getTimeRangeFromIndex(index){
      return {
        start:new Date(new Date().getFullYear(),Static.getMonthIndex(index),1),
        end:new Date(new Date().getFullYear(),Static.getMonthIndex(index-1),0)
      }
    },
    getTimeRangeFromDays(days){
      var date=new Date();
      var dateDays=new Date();
      dateDays.setDate(date.getDate()-days);
      return {
        start:new Date(dateDays.getFullYear(),dateDays.getMonth(),dateDays.getDate()),
        end:new Date(date.getFullYear(),date.getMonth(),date.getDate())
      }
    },
    onAllClick(){
      var that=this;
      this.setOrderSearchWord('');
      this.setTimeRange(Static.ALL_TIME_RANGE);
      if(this.getCancelTokenSearchOrder!=null)this.getCancelTokenSearchOrder.cancel('nuova query');
      this.setCancelTokenSearchOrder(axios.CancelToken.source());
      var urlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace(Static.FROMDATE,'fromdate/'+Static.changeDateFormat(this.getTimeRange.start))
      .replace(Static.TODATE,'todate/'+Static.changeDateFormat(this.getTimeRange.end))
      .replace("/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA/orderid/ID-ORDINE/sku/SKU-PRODOTTO",'')
      .replace(Static.IDNEGOZIO,this.getUser.id);
      this.setIsLoadingCustomers(true);
      axios.get(urlGetOrdersWithParameters,{cancelToken:this.getCancelTokenSearchOrder.token})
      .then((response)=>{ 
        this.setOrderList(Static.refactorOrderList(response.data)); 
        this.setIsLoadingCustomers(false);
      })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) that.setIsLoadingCustomers(false);
        else if(thrown.response.status==403) {
          that.setNavigation(Static.LOGIN);
          that.deleteUser(this.getUser);
          that.setIsLoadingCustomers(false);
        }
      });
    },
    onDaysClick(days){
      var that=this;
      this.setOrderSearchWord('');
      this.setTimeRange(this.getTimeRangeFromDays(days));
      if(this.getCancelTokenSearchOrder!=null)this.getCancelTokenSearchOrder.cancel('nuova query');
      this.setCancelTokenSearchOrder(axios.CancelToken.source());
      var urlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace(Static.FROMDATE,'fromdate/'+Static.changeDateFormat(this.getTimeRange.start))
      .replace(Static.TODATE,'todate/'+Static.changeDateFormat(this.getTimeRange.end))
      .replace("/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA/orderid/ID-ORDINE/sku/SKU-PRODOTTO",'')
      .replace(Static.IDNEGOZIO,this.getUser.id);
      this.setIsLoadingCustomers(true);
      axios.get(urlGetOrdersWithParameters,{cancelToken:this.getCancelTokenSearchOrder.token})
      .then((response)=>{ 
        this.setOrderList(Static.refactorOrderList(response.data)); 
        this.setIsLoadingCustomers(false);
      })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) that.setIsLoadingCustomers(false);
        else if(thrown.response.status==403) {
          that.setNavigation(Static.LOGIN);
          that.deleteUser(this.getUser);
          that.setIsLoadingCustomers(false);
        }
      });
    },
    onMonthClick(item){
      var that=this;
      this.setOrderSearchWord('');
      this.setTimeRange(this.getTimeRangeFromIndex(item));
      if(this.getCancelTokenSearchOrder!=null)this.getCancelTokenSearchOrder.cancel('nuova query');
      this.setCancelTokenSearchOrder(axios.CancelToken.source());
      var urlGetOrdersWithParameters=this.getUrlGetOrders
      .replace(Static.EMAIL,this.getUser.mail)
      .replace(Static.TOKEN,this.getUser.token)
      .replace(Static.FROMDATE,'fromdate/'+Static.changeDateFormat(this.getTimeRange.start))
      .replace(Static.TODATE,'todate/'+Static.changeDateFormat(this.getTimeRange.end))
      .replace("/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA/orderid/ID-ORDINE/sku/SKU-PRODOTTO",'')
      .replace(Static.IDNEGOZIO,this.getUser.id);
      this.setIsLoadingCustomers(true);
      axios.get(urlGetOrdersWithParameters,{cancelToken:this.getCancelTokenSearchOrder.token})
      .then((response)=>{ 
        this.setOrderList(Static.refactorOrderList(response.data)); 
        this.setIsLoadingCustomers(false);
      })
      .catch(function (thrown) {
        if (!axios.isCancel(thrown)) that.setIsLoadingCustomers(false);
        else if(thrown.response.status==403) {
          that.setNavigation(Static.LOGIN);
          that.deleteUser(this.getUser);
          that.setIsLoadingCustomers(false);
        }
          
      });
    },
    isEqualTimeRange(a,b){
      if(a.start!='' && b.start!='' && a.end!='' && b.end!='')
        return Static.isEqualTimeRange(a,b);
      else
        true
    },
    filterClick(item,type){
      var clonedOrderFilters={...this.orderFilters};
      var itemIndex=Static.checkKey(this.orderFilters[type],item);
      if(itemIndex!=-1) clonedOrderFilters[type].splice(itemIndex,1);
      else clonedOrderFilters[type].push(item);
      this.orderFilters=clonedOrderFilters;
    },
    calendarIconClick(){
      if(this.getTimeRange==Static.NULL_TIME_RANGE)this.setTimeRange(Static.TODAY_TIME_RANGE);
      this.setRightNavigation(Static.CALENDAR)
    },
    reachEnd(){
      if(this.getCustomerSearchWord=='' && this.getRightNavigation==Static.CUSTOMERS_LIST && this.getCustomersPage>1 && !this.getIsLoadingCustomers){
        var customersUrlWithParameters=this.getUrlCustomers
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace(Static.FROMDATE,'')
        .replace(Static.TODATE,'')
        .replace(Static.SIZE,'50')
        .replace(Static.PAGE,this.getCustomersPage);
        this.setIsLoadingCustomers(true);
        this.setCustomersPage(this.getCustomersPage+1);
        if(this.cancelToken!=null)this.cancelToken.cancel('nuova query');
        this.cancelToken=axios.CancelToken.source();
        axios.get(customersUrlWithParameters,{cancelToken:this.cancelToken.token})
        .then((response)=>{ 
          var customersList=[...this.getCustomersList];
          this.setCustomersList([...customersList,...response.data]); 
        })
        .catch(err=>{if(err.response.status==403) {
          this.setNavigation(Static.LOGIN);
          this.deleteUser(this.getUser);
        }})
        .finally(()=>this.setIsLoadingCustomers(false))
      }
    },
    setEmail(evt){
      this.setCustomerToAdd({value:evt,type:'email'});
      if(this.cancelToken!=null)this.cancelToken.cancel('nuova query');
      this.cancelToken=axios.CancelToken.source();
      if(evt.trim()!='' && this.getRegistrationType==Static.REGISTRATION_TYPE_USER){
        this.setIsLoadingCustomer(true);
        var urlCustomerWithParameters=this.getUrlCustomer
        .replace(Static.EMAIL,this.getUser.mail)
        .replace(Static.TOKEN,this.getUser.token)
        .replace('/phone/CUSTOMER-PHONE/info/CUSTOMER-FIRSTNAME-LASTNAME','')
        .replace(Static.CUSTOMER_EMAIL,evt.trim());
        axios.get(urlCustomerWithParameters,{cancelToken:this.cancelToken.token})
        .then((res)=>{
          if(res.data[0]) this.setIsValidMail(false);
          else this.setIsValidMail(true);
          this.setIsLoadingCustomer(false);
        })
        .catch(function (thrown) {
          if (!axios.isCancel(thrown)) this.setIsValidMail(true)
          else if(thrown.response.status==403) {
            this.setNavigation(Static.LOGIN);
            this.deleteUser(this.getUser);
            this.setIsValidMail(true)
        }});
      } else { 
        this.setIsValidMail(true);
        this.setIsLoadingCustomer(false);
      }
    },
    missingFieldCheck(type){
      return !this.getDefaultBilling || !this.getDefaultBilling.address || !this.getDefaultBilling.address[type] || this.getDefaultBilling.address[type]==''
    }
  }
}
</script>

<style src="./ContainerContentRight.css" scoped></style>