import moment from "moment";

export default class Static{
  static LOGIN="login";
  static SEARCH="search";
  static CART="Carrello";
  static FILTER="Filtri";
  static CUSTOMERS_LIST="Customers";
  static CUSTOMER_CHOICE="Customer_choice";
  static NEW_CUSTOMER="New_customer";
  static NEW_COUPON="New_coupon";
  static CUSTOM_COUPON="Custom_coupon";
  static PAYMENT_METHOD="Payment_method";
  static ACCONTO="Acconto";
  static ORDER_CONFIRMED="Order_confirmed";
  static SAVE_NEW_CUSTOMER="Save_customer";
  static SHIPPING_CHOICE="Shipping_choice";
  static NEW_SHIPPING="New_shipping";
  static ORDERS_LABEL="orders";
  static ORDER_DETAIL="Order_detail";
  static CALENDAR="Calendar";
  static SHIPPING_PLACE="shipping_place";
  static REGISTRATION_TYPE_GUEST="guest";
  static REGISTRATION_TYPE_USER="user";
  static ONLY_TELEPHONE="only_telephone";
  static EURO="€";
  static PERCENT="%";
  static PAYMENT_TYPE_PAID="Pagato";
  static PAYMENT_TYPE_TO_PAY="Da Pagare per intero";
  static PAYMENT_TYPE_ACCONTO="+ Acconto";

  static EMPTY_MISSING_INFO={
    firstname:"",
    lastname:"",
    country:"",
    region:"",
    city:"",
    postcode:"",
    telephone:"",
    streetname:""
  };
  static EMPTY_ORDER_FILTERS={
    status:[],
    label:[]
  };
  static EMPTY_TIME_RANGE={
    start:new Date(new Date().getFullYear(),Static.getMonthIndex(0),1),
    end:new Date(new Date().getFullYear(),Static.getMonthIndex(-1),0)
  };
  static TODAY_TIME_RANGE={
    start:new Date(),
    end:new Date() 
  };
  static NULL_TIME_RANGE={
    start:null,
    end:null
  };
  static ALL_TIME_RANGE={
    start:new Date('01/01/1900'),
    end:new Date()
  };
  static EMPTY_COUPON={
    active:false,
    import:'0',
    id:"",
    name:"",
    description:"",
  };
  static EMPTY_ADDRESS={
    firstname:'',
    lastname:'',
    company:'',
    telephone:'',
    streetname:'',
    city:'',
    postcode:'',
    country:'IT',
    defaultbilling:false,
    defaultshipping:false,
    fax: "",
    region: "",
    streetnumber: "",
  };
  static EMPTY_CUSTOMER={
    "firstname": "",
    "lastname": "",
    "email": "",
    "password": "",
    "addresses": [{
        "defaultbilling": true,
        "defaultshipping": true,
        "firstname": "",
        "lastname": "",
        "streetname": "",
        "streetnumber": "",
        "city": "",
        "region": "",
        "postcode": "",
        "country": "IT",
        "telephone": "",
        "fax": "",
        "company": ""
    }]
  };
  static ORDERS_LIST=[
    {key:"score",label:"Pertinenza",value:"DESC"},
    {key:"selling_price",label:"Prezzo Decrescente",value:"DESC"},
    {key:"selling_price",label:"Prezzo Crescente",value:"ASC"}
  ];
  static COUNTRY_LIST=[
{value:'', text:'Paese*'},
{value:'AF', text:'Afghanistan'},
{value:'AX', text:'Åland Islands'},
{value:'AL', text:'Albania'},
{value:'DZ', text:'Algeria'},
{value:'AS', text:'American Samoa'},
{value:'AD', text:'Andorra'},
{value:'AO', text:'Angola'},
{value:'AI', text:'Anguilla'},
{value:'AQ', text:'Antarctica'},
{value:'AG', text:'Antigua Barbuda'},
{value:'AR', text:'Argentina'},
{value:'AM', text:'Armenia'},
{value:'AW', text:'Aruba'},
{value:'AU', text:'Australia'},
{value:'AT', text:'Austria'},
{value:'AZ', text:'Azerbaijan'},
{value:'BS', text:'Bahamas'},
{value:'BH', text:'Bahrain'},
{value:'BD', text:'Bangladesh'},
{value:'BB', text:'Barbados'},
{value:'BY', text:'Belarus'},
{value:'BE', text:'Belgium'},
{value:'BZ', text:'Belize'},
{value:'BJ', text:'Benin'},
{value:'BM', text:'Bermuda'},
{value:'BT', text:'Bhutan'},
{value:'BO', text:'Bolivia'},
{value:'BA', text:'Bosnia Herzegovina'},
{value:'BW', text:'Botswana'},
{value:'BV', text:'Bouvet Island'},
{value:'BR', text:'Brazil'},
{value:'IO', text:'British Indian Ocean Territory'},
{value:'VG', text:'British Virgin Islands'},
{value:'BN', text:'Brunei'},
{value:'BG', text:'Bulgaria'},
{value:'BF', text:'Burkina Faso'},
{value:'BI', text:'Burundi'},
{value:'KH', text:'Cambodia'},
{value:'CM', text:'Cameroon'},
{value:'CA', text:'Canada'},
{value:'CV', text:'Cape Verde'},
{value:'KY', text:'Cayman Islands'},
{value:'CF', text:'Central African Republic'},
{value:'TD', text:'Chad'},
{value:'CL', text:'Chile'},
{value:'CN', text:'China'},
{value:'CX', text:'Christmas Island'},
{value:'CC', text:'Cocos (Keeling) Islands'},
{value:'CO', text:'Colombia'},
{value:'KM', text:'Comoros'},
{value:'CG', text:'Congo - Brazzaville'},
{value:'CD', text:'Congo - Kinshasa'},
{value:'CK', text:'Cook Islands'},
{value:'CR', text:'Costa Rica'},
{value:'CI', text:'Côte d’Ivoire'},
{value:'HR', text:'Croatia'},
{value:'CU', text:'Cuba'},
{value:'CY', text:'Cyprus'},
{value:'CZ', text:'Czech Republic'},
{value:'DK', text:'Denmark'},
{value:'DJ', text:'Djibouti'},
{value:'DM', text:'Dominica'},
{value:'DO', text:'Dominican Republic'},
{value:'EC', text:'Ecuador'},
{value:'EG', text:'Egypt'},
{value:'SV', text:'El Salvador'},
{value:'GQ', text:'Equatorial Guinea'},
{value:'ER', text:'Eritrea'},
{value:'EE', text:'Estonia'},
{value:'ET', text:'Ethiopia'},
{value:'FK', text:'Falkland Islands'},
{value:'FO', text:'Faroe Islands'},
{value:'FJ', text:'Fiji'},
{value:'FI', text:'Finland'},
{value:'FR', text:'France'},
{value:'GF', text:'French Guiana'},
{value:'PF', text:'French Polynesia'},
{value:'TF', text:'French Southern Territories'},
{value:'GA', text:'Gabon'},
{value:'GM', text:'Gambia'},
{value:'GE', text:'Georgia'},
{value:'DE', text:'Germany'},
{value:'GH', text:'Ghana'},
{value:'GI', text:'Gibraltar'},
{value:'GR', text:'Greece'},
{value:'GL', text:'Greenland'},
{value:'GD', text:'Grenada'},
{value:'GP', text:'Guadeloupe'},
{value:'GU', text:'Guam'},
{value:'GT', text:'Guatemala'},
{value:'GG', text:'Guernsey'},
{value:'GN', text:'Guinea'},
{value:'GW', text:'Guinea-Bissau'},
{value:'GY', text:'Guyana'},
{value:'HT', text:'Haiti'},
{value:'HM', text:'Heard McDonald Islands'},
{value:'HN', text:'Honduras'},
{value:'HK', text:'Hong Kong SAR China'},
{value:'HU', text:'Hungary'},
{value:'IS', text:'Iceland'},
{value:'IN', text:'India'},
{value:'ID', text:'Indonesia'},
{value:'IR', text:'Iran'},
{value:'IQ', text:'Iraq'},
{value:'IE', text:'Ireland'},
{value:'IM', text:'Isle of Man'},
{value:'IL', text:'Israel'},
{value:'IT', text:'Italy'},
{value:'JM', text:'Jamaica'},
{value:'JP', text:'Japan'},
{value:'JE', text:'Jersey'},
{value:'JO', text:'Jordan'},
{value:'KZ', text:'Kazakhstan'},
{value:'KE', text:'Kenya'},
{value:'KI', text:'Kiribati'},
{value:'KW', text:'Kuwait'},
{value:'KG', text:'Kyrgyzstan'},
{value:'LA', text:'Laos'},
{value:'LV', text:'Latvia'},
{value:'LB', text:'Lebanon'},
{value:'LS', text:'Lesotho'},
{value:'LR', text:'Liberia'},
{value:'LY', text:'Libya'},
{value:'LI', text:'Liechtenstein'},
{value:'LT', text:'Lithuania'},
{value:'LU', text:'Luxembourg'},
{value:'MO', text:'Macau SAR China'},
{value:'MK', text:'Macedonia'},
{value:'MG', text:'Madagascar'},
{value:'MW', text:'Malawi'},
{value:'MY', text:'Malaysia'},
{value:'MV', text:'Maldives'},
{value:'ML', text:'Mali'},
{value:'MT', text:'Malta'},
{value:'MH', text:'Marshall Islands'},
{value:'MQ', text:'Martinique'},
{value:'MR', text:'Mauritania'},
{value:'MU', text:'Mauritius'},
{value:'YT', text:'Mayotte'},
{value:'MX', text:'Mexico'},
{value:'FM', text:'Micronesia'},
{value:'MD', text:'Moldova'},
{value:'MC', text:'Monaco'},
{value:'MN', text:'Mongolia'},
{value:'ME', text:'Montenegro'},
{value:'MS', text:'Montserrat'},
{value:'MA', text:'Morocco'},
{value:'MZ', text:'Mozambique'},
{value:'MM', text:'Myanmar (Burma)'},
{value:'NA', text:'Namibia'},
{value:'NR', text:'Nauru'},
{value:'NP', text:'Nepal'},
{value:'NL', text:'Netherlands'},
{value:'AN', text:'Netherlands Antilles'},
{value:'NC', text:'New Caledonia'},
{value:'NZ', text:'New Zealand'},
{value:'NI', text:'Nicaragua'},
{value:'NE', text:'Niger'},
{value:'NG', text:'Nigeria'},
{value:'NU', text:'Niue'},
{value:'NF', text:'Norfolk Island'},
{value:'MP', text:'Northern Mariana Islands'},
{value:'KP', text:'North Korea'},
{value:'NO', text:'Norway'},
{value:'OM', text:'Oman'},
{value:'PK', text:'Pakistan'},
{value:'PW', text:'Palau'},
{value:'PS', text:'Palestinian Territories'},
{value:'PA', text:'Panama'},
{value:'PG', text:'Papua New Guinea'},
{value:'PY', text:'Paraguay'},
{value:'PE', text:'Peru'},
{value:'PH', text:'Philippines'},
{value:'PN', text:'Pitcairn Islands'},
{value:'PL', text:'Poland'},
{value:'PT', text:'Portugal'},
{value:'QA', text:'Qatar'},
{value:'RE', text:'Réunion'},
{value:'RO', text:'Romania'},
{value:'RU', text:'Russia'},
{value:'RW', text:'Rwanda'},
{value:'WS', text:'Samoa'},
{value:'SM', text:'San Marino'},
{value:'ST', text:'São Tomé Príncipe'},
{value:'SA', text:'Saudi Arabia'},
{value:'SN', text:'Senegal'},
{value:'RS', text:'Serbia'},
{value:'SC', text:'Seychelles'},
{value:'SL', text:'Sierra Leone'},
{value:'SG', text:'Singapore'},
{value:'SK', text:'Slovakia'},
{value:'SI', text:'Slovenia'},
{value:'SB', text:'Solomon Islands'},
{value:'SO', text:'Somalia'},
{value:'ZA', text:'South Africa'},
{value:'GS', text:'South Georgia South Sandwich Islands'},
{value:'KR', text:'South Korea'},
{value:'ES', text:'Spain'},
{value:'LK', text:'Sri Lanka'},
{value:'BL', text:'St. Barthélemy'},
{value:'SH', text:'St. Helena'},
{value:'KN', text:'St. Kitts Nevis'},
{value:'LC', text:'St. Lucia'},
{value:'MF', text:'St. Martin'},
{value:'PM', text:'St. Pierre Miquelon'},
{value:'VC', text:'St. Vincent Grenadines'},
{value:'SD', text:'Sudan'},
{value:'SR', text:'Suriname'},
{value:'SJ', text:'Svalbard Jan Mayen'},
{value:'SZ', text:'Swaziland'},
{value:'SE', text:'Sweden'},
{value:'CH', text:'Switzerland'},
{value:'SY', text:'Syria'},
{value:'TW', text:'Taiwan'},
{value:'TJ', text:'Tajikistan'},
{value:'TZ', text:'Tanzania'},
{value:'TH', text:'Thailand'},
{value:'TL', text:'Timor-Leste'},
{value:'TG', text:'Togo'},
{value:'TK', text:'Tokelau'},
{value:'TO', text:'Tonga'},
{value:'TT', text:'Trinidad Tobago'},
{value:'TN', text:'Tunisia'},
{value:'TR', text:'Turkey'},
{value:'TM', text:'Turkmenistan'},
{value:'TC', text:'Turks Caicos Islands'},
{value:'TV', text:'Tuvalu'},
{value:'UG', text:'Uganda'},
{value:'UA', text:'Ukraine'},
{value:'AE', text:'United Arab Emirates'},
{value:'GB', text:'United Kingdom'},
{value:'US', text:'United States'},
{value:'UY', text:'Uruguay'},
{value:'UM', text:'U.S. Outlying Islands'},
{value:'VI', text:'U.S. Virgin Islands'},
{value:'UZ', text:'Uzbekistan'},
{value:'VU', text:'Vanuatu'},
{value:'VA', text:'Vatican City'},
{value:'VE', text:'Venezuela'},
{value:'VN', text:'Vietnam'},
{value:'WF', text:'Wallis Futuna'},
{value:'EH', text:'Western Sahara'},
{value:'YE', text:'Yemen'},
{value:'ZM', text:'Zambia'},
{value:'ZW', text:'Zimbabwe'},
  ];

  static DEV_BASE_SITE="https://dev.motoabbigliamento.it/";
  // static PROD_BASE_SITE="https://www.motoabbigliamento.it";
  static LOGIN="/ittweb_webpos/index/login?username=EMAIL-ACCESSO&password=PASSWORD";
  static CHECK="/ittweb_webpos/index/checktoken/iduser/EMAIL-ACCESSO/token/TOKEN";
  static CUSTOMERS="/ittweb_webpos/index/getcustomers/iduser/EMAIL-ACCESSO/token/TOKEN/fromdate/ANNO-M-G/todate/ANNO-M-G/size/SIZE/page/PAGE/storeid/IDNEGOZIO";
  static CUSTOMER="/ittweb_webpos/index/searchcustomer/iduser/EMAIL-ACCESSO/token/TOKEN/email/CUSTOMER-EMAIL/phone/CUSTOMER-PHONE/info/CUSTOMER-FIRSTNAME-LASTNAME";
  static SAVE_CUSTOMER="/ittweb_webpos/index/savenewcustomer/iduser/EMAIL-ACCESSO/token/TOKEN";
  static SAVE_ADDRESS="/ittweb_webpos/index/savenewaddress/iduser/EMAIL-ACCESSO/token/TOKEN";
  static ORDERS="/ittweb_webpos/index/getorder/iduser/EMAIL-ACCESSO/token/TOKEN/fromdate/ANNO-M-G/todate/ANNO-M-G/storeid/IDNEGOZIO/email/EMAIL-RICERCA/firstname/NOME-RICERCA/lastname/COGNOME-RICERCA/orderid/ID-ORDINE/sku/SKU-PRODOTTO";
  static EMAIL_ORDER="/ittweb_webpos/index/emailorder/iduser/EMAIL-ACCESSO/token/TOKEN";
  static ETICHETTA="/ittweb_webpos/index/setlabel/iduser/EMAIL-ACCESSO/token/TOKEN"
  static SAVE_ORDER="/ittweb_webpos/index/sendorder/iduser/EMAIL-ACCESSO/token/TOKEN";
  static COUPON="/ittweb_webpos/index/getcoupon/iduser/EMAIL-ACCESSO/token/TOKEN/couponcode/CODICE-COUPON";
  static PRICE_RULES="/ittweb_webpos/index/getcartpricerules/iduser/EMAIL-ACCESSO/token/TOKEN";
  static ORDER_PRICE="/ittweb_webpos/index/getorderprice/iduser/EMAIL-ACCESSO/token/TOKEN";
  static SAVE_BILLING="/ittweb_webpos/index/updatebillingaddress/iduser/EMAIL-ACCESSO/token/TOKEN";
  // static SAVE_BILLING="/ittweb_webpos/index/updatebillingaddress";
  static ETA="/ittweb_webpos/index/getdeliverydate/iduser/EMAIL-ACCESSO/token/TOKEN/idproduct/ID-PRODOTTO";
  static COMMENT="/ittweb_webpos/index/addordercomment/iduser/EMAIL-ACCESSO/token/TOKEN";

  static EMAIL="EMAIL-ACCESSO";
  static CUSTOMER_EMAIL="CUSTOMER-EMAIL";
  static CUSTOMER_PHONE="CUSTOMER-PHONE";
  static CUSTOMER_FIRSTNAME_LASTNAME="CUSTOMER-FIRSTNAME-LASTNAME";
  static PASSWORD="PASSWORD";
  static EMAIL_RICERCA="EMAIL-RICERCA";
  static NOME_RICERCA="NOME-RICERCA";
  static COGNOME_RICERCA="COGNOME-RICERCA";
  static ID_ORDINE="ID-ORDINE";
  static ID_PRODOTTO="ID-PRODOTTO";
  static SKU_PRODOTTO="SKU-PRODOTTO";
  static TOKEN="TOKEN";
  static FROMDATE="fromdate/ANNO-M-G";
  static TODATE="todate/ANNO-M-G";
  static COUPON_CODE="CODICE-COUPON";
  static SIZE="SIZE";
  static PAGE="PAGE";
  static NOTE="NOTE";
  static IDNEGOZIO="IDNEGOZIO";

  static MAIL="MAIL";
  static NUMBER="NUMBER";
  static NAME="NAME";
  static SHOP="SHOP";
  static HOME="HOME";
  static ROWS=24;
  static paramsToReturn="&attributes[]=name&attributes[]=image&attributes[]=pricing&attributes[]=variants&attributes[]=bundles&attributes[]=sku&attributes[]=id&attributes[]=invent_m9&attributes[]=invent_negozi&attributes[]=description";

  // static BASE_SEARCH_URL="https://accelasearch.dev1.accelasearch.net/?shop_id=shop_60&filters[0][0][quantity][from]=1&rows="+Static.ROWS;
  // static BASE_SEARCH_URL="https://accelasearch-dev.dev1.accelasearch.net/?shop_id=shop_52&filters[0][0][quantity][from]=1&currency=EUR&customer_group_id=1&rows="+Static.ROWS;
  static DEV_BASE_URL="https://accelasearch-dev.dev1.accelasearch.net/?shop_id=shop_61"
  static PROD_BASE_URL="https://svc10.accelasearch.io/?shop_id=shop_4"
  static STAG_BASE_URL="https://svc10.accelasearch.io/?shop_id=shop_61"
  static BASE_SEARCH_URL="&currency=EUR&customer_group_id=1&filters[0][0][quantity][from]=1&rows="+Static.ROWS+Static.paramsToReturn;
  // static BASE_SEARCH_URL="&currency=EUR&customer_group_id=1&rows="+Static.ROWS+Static.paramsToReturn;
  static DEV_HOSTNAME= "motoabb.dev56.ittweb.net";
  static STAG_HOSTNAME= "dev.motoabbigliamento.it";
  static PROD_HOSTNAME= "www.motoabbigliamento.it";

  static STATUS_CANCELLATO={label:'Cancellato',key:'canceled'};
  static STATUS_PROCESSING={label:'Processing',key:'processing'};
  static STATUS_CONFERMATO={label:'Confermato',key:'confermato'};
  static STATUS_COMPLETATO={label:'Completato',key:'complete'};
  static STATUS_ARRAY=[this.STATUS_CANCELLATO,this.STATUS_PROCESSING,this.STATUS_CONFERMATO,this.STATUS_COMPLETATO];
  static ETICHETTA_0={label:'Nessuna',key:0};
  static ETICHETTA_1={label:'Impegnato',key:1};
  static ETICHETTA_2={label:'Non Ritirato',key:2};
  static ETICHETTA_3={label:'Consegnato',key:3};
  static ETICHETTA_4={label:'Avvisato a Telefono',key:4};
  static ETICHETTE_ARRAY=[this.ETICHETTA_0,this.ETICHETTA_1,this.ETICHETTA_4,this.ETICHETTA_2,this.ETICHETTA_3];

  static EMAIL_REGEX= /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  static getBaseUrl(){
    var urlToReturn=Static.DEV_BASE_URL;
    switch(window.location.hostname){
      case Static.DEV_HOSTNAME: urlToReturn=Static.DEV_BASE_URL;break;
      case Static.STAG_HOSTNAME: urlToReturn=Static.STAG_BASE_URL;break;
      case Static.PROD_HOSTNAME: urlToReturn=Static.PROD_BASE_URL;break;
      default: urlToReturn=Static.DEV_BASE_URL;break;
    }
    urlToReturn=(window.location.hostname==="www.motoabbigliamento.it"?Static.PROD_BASE_URL:Static.DEV_BASE_URL)+Static.BASE_SEARCH_URL
    return urlToReturn;
  }

  static openFullscreen(elem) {
      if (elem.requestFullscreen) { elem.requestFullscreen(); } 
      else if (elem.mozRequestFullScreen) { /* Firefox */ elem.mozRequestFullScreen(); } 
      else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */ elem.webkitRequestFullscreen(); } 
      else if (elem.msRequestFullscreen) { /* IE/Edge */ elem.msRequestFullscreen(); }
  }

  static checkSku(array,itemToCheck){
    var indexToReturn=-1;
    array.forEach((item,index)=>{
      if(item.data.sku==itemToCheck.sku){
        indexToReturn=index;        
        return;
      }
    });
    return indexToReturn;
  }

  static getTotal(cart){
    var total=0;
    cart.forEach(item=>{
      var priceToUse=Static.isNotZeroOrNull(item.pricing['1']['0.0000']['EUR'].selling_price)?
          item.pricing['1']['0.0000']['EUR'].selling_price:
          item.pricing['1']['0.0000']['EUR'].listing_price;
      total+=priceToUse*item.cartQuantity;
    });
    return total.toFixed(2);
  }

  static getReceiptTotal(cart){
    var total=0;
    cart.forEach(item=>{ total+=item.total*item.qty; });
    return total.toFixed(2);
  }

  static getInputType(input){
    var isNumber= /^[0-9]*$/;
    return Static.EMAIL_REGEX.test(input.replace(' ',''))?Static.MAIL:(isNumber.test(input.replace(' ',''))?Static.NUMBER:Static.NAME);
  }

  static CUSTOMER_JSON_SAMPLE={
    "firstname": "Roberto",
    "lastname": "Bianchi",
    "email": "rbianchi@ittweb.net",
    "password": "testtest",
    "addresses": [{
        "defaultbilling": true,
        "defaultshipping": false,
        "firstname": "",
        "lastname": "cognome",
        "streetname": "prova",
        "streetnumber": "12",
        "city": "aaaacity",
        "region": "regione da verificare",
        "postcode": "10100",
        "country": "IT",
        "telephone": "102938475",
        "fax": "102938475",
        "company": "nomeazienda"
    },{
        "defaultbilling": false,
        "defaultshipping": false,
        "firstname": "abc",
        "lastname": "",
        "streetname": "fgfdsn",
        "streetnumber": "049485",
        "city": "fkjr",
        "region": "regione2 da verificare",
        "postcode": "22222",
        "country": "US",
        "telephone": "22222220",
        "fax": "20222222",
        "company": "nomeazienda2"
    },{
        "defaultbilling": false,
        "defaultshipping": true,
        "firstname": "prova2",
        "lastname": "cognome2",
        "streetname": "prova2",
        "streetnumber": "22222222222",
        "city": "aaaacity22",
        "region": "regione2 da verificare",
        "postcode": "22222",
        "country": "IT",
        "telephone": "22222220",
        "fax": "20222222",
        "company": "nomeazienda2"
      }]
  };
    
  static isLocalhost(){ return window.location.hostname==='localhost'; }
    
  static COMPLETED_ORDER_SAMPLE={
    comment: " | Aggiunto sconto in riga 'test' pari a 10 sul prodotto RIDER550WLD  | Aggiunta promozione a carrello 'Buono sconto Componenti 20% - fagiolo' | Aggiunta promozione a carrello 'Test' | Aggiunta promozione a carrello 'Test 2' | Aggiunta promozione a carrello 'Test 3' | Aggiunta promozione a carrello 'test'",
    groupid: 0,
    manualdiscount: 0,
    orderid: "M00365043",
    productdetails: [{sku: "RIDER550WLD ", price: 354.9, discount: 10, deliverydate: "02-06"}],
    deliverydate: "02-06",
    cartdiscount: 10,
    price: 354.9,
    sku: "RIDER550WLD ",
    quote: {},
    subtotal: 709.8,
    totaldue: 289.59,
    totalpaid: 80,
  }

  SAMPLE_ORDER_LIST=[
    {
      id:"M123123",
      date:"2020-04-05",
      name:"Braccobaldo",
      totale:"120.00",
      pagato:"50.00",
      status:"Completato"
    },{
      id:"M123123",
      date:"2020-04-05",
      name:"Braccobaldo",
      totale:"120.00",
      pagato:"50.00",
      status:"Processing"
    },{
      id:"M123123",
      date:"2020-04-04",
      name:"Braccobaldo",
      totale:"120.00",
      pagato:"50.00",
      status:"Confermato"
    },{
      id:"M123123",
      date:"2020-04-02",
      name:"Braccobaldo",
      totale:"120.00",
      pagato:"50.00",
      status:"Cancellato"
    },{
      id:"M123123",
      date:"2020-04-01",
      name:"Braccobaldo",
      totale:"120.00",
      pagato:"50.00",
      status:"Completato"
    },{
      id:"M123123",
      date:"2020-04-01",
      name:"Braccobaldo",
      totale:"120.00",
      pagato:"50.00",
      status:"Completato"
    },{
      id:"M123123",
      date:"2020-04-01",
      name:"Braccobaldo",
      totale:"120.00",
      pagato:"50.00",
      status:"Completato"
    },
  ]

  static ETICHETTA_JSON_SAMPLE={
    "idorder": "ID ORDINE",
    "skuproduct": "SKU PRODOTTO",
    "idlabel": "ID LABEL"
  }

  static ORDER_JSON_SAMPLE={
    "isguest": "true",
    "firstname": "Roberto",
    "lastname": "Bianchi",
    "id":"M123456789",
    "deliverydate":"19-09",
    "date": "2020-09-16 15:39:59",
    "email": "rbianchi@ittweb.net",
    "paymentmethod": "purchaseorder",
    "shippingmethod": "freeshipping",
    "totaldiscountimport": "10%",
    "totaldiscountname": "Special now",
    "totalpaid": "12.5",
    "products": [{
        "id": "270538",
        "qty": "2",
        "deliverydate": "28-09"
      },{
        "id": "181449",
        "qty": "1",
        "rowdiscountimport": "7",
        "rowdiscountname": "Wow",
        "configurable": [{"key": "142", "value": "402"}],
        "deliverydate": "29-09"
      },{
        "id": "158662",
        "qty": "1",
        "rowdiscountimport": "5%",
        "rowdiscountname": "For product",
        "bundle": [
          {"key": "287","value": "1960"},
          {"key": "288","value": "1965"}
        ],
        "deliverydate": "30-09"
      }
    ],
    "billingaddress": {
      "firstname": "prova",
      "lastname": "cognome",
      "streetname": "prova",
      "streetnumber": "12",
      "city": "aaaacity",
      "region": "regione da verificare",
      "postcode": "10100",
      "country": "IT",
      "telephone": "102938475",
      "fax": "102938475",
      "company": "nomeazienda"
    },
    "shippingaddress": {
      "firstname": "abc",
      "lastname": "adet",
      "streetname": "fgfdsn",
      "streetnumber": "049485",
      "city": "fkjr",
      "region": "regione2 da verificare",
      "postcode": "22222",
      "country": "IT",
      "telephone": "22222220",
      "fax": "20222222",
      "company": "nomeazienda2"
    }
  }

  static filtersWithoutActive(activeFilters,filters,filterKey){
    var oggettoCopiato = {...filters};
    if(filters) Object.keys(filters).forEach((item=>{
      activeFilters.forEach(subItem=>{
        if(subItem.key===filterKey && item===subItem.value) delete oggettoCopiato[subItem.value]
      });
    }));
    return oggettoCopiato;
  }

  static checkKey(array,itemToCheck){
    var indexToReturn=-1;
    array.forEach((item,index)=>{
      if(item.key==itemToCheck.key){
        indexToReturn=index;     
        return;
      }
    });
    return indexToReturn;
  }

  static checkKeyWithValue(array,valueToCheck){
    var indexToReturn=-1;
    array.forEach((item,index)=>{
      if(item.key==valueToCheck){
        indexToReturn=index;        
        return;
      }
    });
    return indexToReturn;
  }

  static refactorAddress(address,defaultbilling=false,defaultshipping=false){
    var addressToReturn={};
    addressToReturn.address={};
    addressToReturn.defaultbilling=defaultbilling;
    addressToReturn.defaultshipping=defaultshipping;
    addressToReturn.firstname=address.firstname;
    addressToReturn.lastname=address.lastname;
    addressToReturn.telephone=address.telephone;
    addressToReturn.address.firstname=address.firstname;
    addressToReturn.address.lastname=address.lastname;
    addressToReturn.address.telephone=address.telephone;
    addressToReturn.address.telephone=address.telephone;
    addressToReturn.address.city=address.city;
    addressToReturn.address.streetname=address.streetname;
    addressToReturn.address.postcode=address.postcode;
    addressToReturn.address.country=address.country;
    return addressToReturn;
  }

  static isNotZeroOrNull(value){ return value!=null && parseInt(value)!==0; }

  static isNotNullOrEmpty(value){ return value!=null && value!=''; }

  static getBillingAddressFromCustomer(customer){
    var valueToReturn={};
    customer.addresses.forEach(address=>{
      if(address.defaultbilling) {
        valueToReturn={...address};
        return false;
      }
    })
    return valueToReturn;
  }

  static isEqualTimeRange(a,b){ return a.start.getTime()==b.start.getTime() && a.end.getTime()==b.end.getTime() }

  static getMonthIndex(index){ return (((new Date().getMonth()-index)%12)+12)%12 }

  static changeDateFormat(date){ return date?date.getFullYear()+"-"+(date.getMonth()+1)+"-"+date.getDate():'' }

  static getLocalDate(date){ 
    moment.locale('it');
    return moment.utc(date).local(); 
  }

  static getLabelFromKey(key,type){
    var labelToReturn='';
    var arrayToCheck=type=='status'?[...Static.STATUS_ARRAY]:[...Static.ETICHETTE_ARRAY];
    arrayToCheck.forEach(item=>{
      if(key==item.key){
        labelToReturn=item.label;
        return false;
      }
    })
    return labelToReturn;
  }

  static printReceipt(){
    var divToPrint=document.getElementById('receiptToPrint');
    var head='<head><title>Ricevuta Motoabbigliamento</title><link rel="stylesheet" type="text/css" href="./Receipt.css"></head>';
    var script='<script> window.onload=()=>window.print() <'+'/script>';
    var newWin=window.open('','Print-Window','top=62px,left=62px,width=912px,height=682px');

    var beforePrint = () =>{};
    var afterPrint = () => { newWin.close(); };
    if (newWin.matchMedia) {
        var mediaQueryList = newWin.matchMedia('print');
        mediaQueryList.addListener(mql=> {if (mql.matches) beforePrint(); else  afterPrint();});
    }
    newWin.onbeforeprint = beforePrint;
    newWin.onafterprint = afterPrint;

    newWin.document.open();
    // newWin.document.write('<html>'+head+'<body>'+divToPrint.innerHTML+'</body></html>');
    newWin.document.write('<html>'+head+'<body>'+divToPrint.innerHTML+'</body></html>'+script);
    newWin.document.close();
  }

  static getLastDate(items,deliverydateLabel){
    var dateArray=[];
    items.forEach(item => {
      dateArray.push(item[deliverydateLabel]);
    });
    dateArray.sort();
    var dateToReturn=dateArray[dateArray.length-1];
    return dateToReturn;
  }

  static refactorOrderList(orderList){
    var arrayToReturn=[];
    orderList.forEach(order=>arrayToReturn.push(Static.refactorOrder(order)))
    return arrayToReturn;
  }

  static refactorOrder(order){
    var valueToReturn={...order};
    if(valueToReturn.status=='spedizione') valueToReturn.status='complete';
    return valueToReturn;
  }
}