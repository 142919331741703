import Static from '@/utils/Static';

export default {
    getters:{
        getBaseSite:()=>Static.isLocalhost()?Static.DEV_BASE_SITE:window.location.origin,
        getUrlLogin:(state,getters)=>getters.getBaseSite+Static.LOGIN,
        getUrlCheck:(state,getters)=>getters.getBaseSite+Static.CHECK,
        getUrlCustomers:(state,getters)=>getters.getBaseSite+Static.CUSTOMERS,
        getUrlCustomer:(state,getters)=>getters.getBaseSite+Static.CUSTOMER,
        getUrlSaveAddress:(state,getters)=>getters.getBaseSite+Static.SAVE_ADDRESS,
        getUrlSaveCustomer:(state,getters)=>getters.getBaseSite+Static.SAVE_CUSTOMER,
        getUrlCheckCoupon:(state,getters)=>getters.getBaseSite+Static.COUPON,
        getUrlSaveOrder:(state,getters)=>getters.getBaseSite+Static.SAVE_ORDER,
        getUrlGetOrders:(state,getters)=>getters.getBaseSite+Static.ORDERS,
        getUrlSetEtichetta:(state,getters)=>getters.getBaseSite+Static.ETICHETTA,
        getUrlSendEmail:(state,getters)=>getters.getBaseSite+Static.EMAIL_ORDER,
        getUrlPriceRules:(state,getters)=>getters.getBaseSite+Static.PRICE_RULES,
        getUrlOrderPrice:(state,getters)=>getters.getBaseSite+Static.ORDER_PRICE,
        getUrlEta:(state,getters)=>getters.getBaseSite+Static.ETA,
        getUrlComment:(state,getters)=>getters.getBaseSite+Static.COMMENT,
        getUrlSaveBilling:(state,getters)=>getters.getBaseSite+Static.SAVE_BILLING,
    }
}